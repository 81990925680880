import React, { Component } from 'react';
import Title from '../../../components/UI/Title/Title';
import CreateBtn from '../../../components/Navigation/PageNavigationItems/CreateNavigationItem';
import Filter from '../../../components/UI/Filter/Filter';
import api from '../../../api/api';
import {UpdatePencil, ViewEye, DeleteTrash} from '../../../components/UI/ActionItems/ActionItems';
import * as Constants from '../../../constant';
import {slugify} from '../../../Utils';

let documentList = null;
let token = localStorage.getItem('token');
let roles = localStorage.getItem('roles');


class Documents extends Component {
    state = {
        documentsArray:[],
        totalDocuments:0,
        currentPage: 1,
        deleted:false,
        minPageLimit:1,
        maxPageLimit:3,
        difference:2,
        documentsPerPage: Constants.documentsPerPage,
        term:'',
        activeInput: '',
        sort: {
            column: null,
            direction: 'desc',
          },
        filterForm: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                hasLabel: false,
                touched: false
            },
            required_administration_time:{
              elementType: 'input',
              elementConfig: {
                  type: 'text',
                  placeholder: ''
              },
              value: '',
              hasLabel: false,
              touched: false
            },
            status: {
                elementType: 'select',
                elementConfig: {
                  options:[
                    {value: '', displayValue:'Összes'},
                    {value: 1, displayValue:'Engedélyezett'},
                    {value: 0, displayValue:'Nem engedélyezett'},
                    
                ]
                },
                value: '',
                hasLabel: false,
                touched: false,
            },
            // date: {
            //     elementType: 'input',
            //     elementConfig: {
            //         type: 'text',
            //         placeholder: ''
            //     },
            //     value: '',
            //     hasLabel: false,
            //     touched: false
            // },
        },
    }

    componentDidMount(){
      this.getDataFromServer();
    }

    getDataFromServer = () => {
      api.get({url: 'document-types'},token).then(
        response => {
            this.setState({documentsArray:response.data})
            this.setState({totalDocuments:response.data.length})

        }
    ).catch(
        e => {
           console.log(e);
        }
    )
    }

    inputChangeHandler = (event, inputIdentifier) => {
        
      const updatedFilter = {
          ...this.state.filterForm
      };
      const updatedFilterElement = {
          ...updatedFilter[inputIdentifier]
      };
      updatedFilterElement.value = event.target.value;
      updatedFilterElement.touched = true;
      updatedFilter[inputIdentifier] = updatedFilterElement;


      this.setState({ filterForm: updatedFilter, term: event.target.value, activeInput:inputIdentifier});
  }

    onSort = (column) => (e) => {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const sortedData = this.state.documentsArray.sort((a, b) => {
          if (column === Constants.name) {
            const nameA = slugify(a.name.trim().toUpperCase()); // ignore upper and lowercase
            const nameB = slugify(b.name.trim().toUpperCase()); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          } else {
            const nameA = (a.created_at); // ignore upper and lowercase
            const nameB = (a.created_at); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          }
        });
          
        if (direction === 'desc') {
          sortedData.reverse();
        }
        
        this.setState({
          data: sortedData,
          sort: {
            column,
            direction,
          }
        });
      };

    setArrow = (column) => {
    let className = 'sort-direction';
    
    if (this.state.sort.column === column) {
        className += this.state.sort.direction === 'asc' ? ' fa fa-arrow-up' : ' fa fa-arrow-down';
    }
    return className;
    };

    currentPageHandler = (e) => {
        this.setState({
          currentPage: Number(e.target.id)
        });
      }

    searchingFor = (term,identifier) => {
    return function(document) {
      if(identifier == 'status'){
        return (identifier!= '' && String(document.enabled)== term) || !term;
      }
      // else if(identifier == 'date'){
      //   return (identifier!= '' && (document.created_at ? document.created_at : '0').includes(term)) || !term;
      // }
      else{
          return (identifier!= '' && document[identifier].toLowerCase().includes(term.toLowerCase())) || !term;
      }
    }
    }

    componentDidUpdate(){
      if(this.state.deleted == true){
        setTimeout(() => {
          this.getDataFromServer();
          this.setState({deleted:false})
        }, 150);
        
        
      }
    }

    changeData = () => {
      this.setState({deleted:true})
    }

    increaseing = () => {
      if(this.state.maxPageLimit < Math.ceil(this.state.documentsArray.length / this.state.documentsPerPage)){
          this.setState({ maxPageLimit: this.state.maxPageLimit+1, minPageLimit:this.state.minPageLimit+1});
      }
    }

    decreaseing = () => {
      if(this.state.minPageLimit > 1){
          this.setState({ maxPageLimit: this.state.maxPageLimit-1, minPageLimit:this.state.minPageLimit-1});
      }
    }
    decreaseingToMinim = () => {
      this.setState({ maxPageLimit: this.state.difference+1, minPageLimit:1});
    }

    increaseingToMaxim = () => {
      this.setState({ maxPageLimit: Math.ceil(this.state.documentsArray.length / this.state.documentsPerPage), minPageLimit:Math.ceil(this.state.documentsArray.length / this.state.documentsPerPage)-this.state.difference});
    }


    render() {
        const { documentsArray, term, currentPage,  documentsPerPage } = this.state;
        const indexOfLastDocument = currentPage * documentsPerPage;
        const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
        const currentDocuments = this.state.documentsArray.length ? this.state.documentsArray.slice(indexOfFirstDocument, indexOfLastDocument) : null;
        const documentListWithTerm = this.state.documentsArray.length ? (
            <tbody>
                {
                    
                documentsArray.filter(this.searchingFor(term,this.state.activeInput)).map(document => {
                return (
                  <tr key={document.id + Math.random()} data-key={document.id}>
                    <td>{document.id}</td>
                    <td >{document.name}</td>
                    <td>{document.required_administration_time} nap</td>
                    <td>{document.enabled ? "Engedélyezett": 'Nem engedélyezett'}</td>
                    <td>{document.created_at}</td>
                    <td onClick={this.changeData} id={document.id} >
                        {roles && roles.search('UPDATE') > -1 && <UpdatePencil link={"document-types/update/"+document.id}/>}
                        {/* {roles && roles.search('DELETE') > -1 && <DeleteTrash link={"document-types/"+document.id}/>} */}
                    </td>
                  </tr>
                ) 
                })
                }
            </tbody>
            
          ) : (
            <tbody>
                <tr><th>No data found...</th></tr>
            </tbody>
          )
        documentList = currentDocuments ? (
            <tbody>
                {
                    currentDocuments.map(document => (
                        <tr key={document.id + Math.random()} data-key={document.id}>
                            <td>{document.id}</td>
                            <td >{document.name}</td>
                            <td>{document.required_administration_time} nap</td>
                            <td>{document.enabled ? "Engedélyezett": 'Nem engedélyezett'}</td>
                            <td>{document.created_at}</td>
                            <td onClick={this.changeData} id={document.id} >
                                {roles && roles.search('UPDATE') > -1 && <UpdatePencil link={"document-types/update/"+document.id}/>}
                                {/* {roles && roles.search('DELETE') > -1 && <DeleteTrash link={"document-types/"+document.id}/>} */}
                            </td>
                        </tr>
                    ))
            
                }
            </tbody>
        ) : (
            <tbody>
                <tr><th>No data found...</th></tr>
            </tbody>
          );

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(documentsArray.length / documentsPerPage); i++) {
          if(i >= this.state.minPageLimit && i <= this.state.maxPageLimit)
            pageNumbers.push(i);
        }
        
        const renderPageNumbers = pageNumbers.map(number => {
            return (
              <li
                key={number}
                id={number}
                onClick={this.currentPageHandler}
              >
                {number}
              </li>
            );
          });
        
        return (
            <div className="PageContent">
                <Title >Dokumentum Típusok</Title>
                {roles && roles.search('CREATE') >-1 && <CreateBtn btnStyle="btn" link="documents/create">+ Új Dokumentum Típus </CreateBtn>}
                <div className="summary">Megjelenik {this.state.totalDocuments <documentsPerPage ? this.state.totalDocuments : documentsPerPage} a(z) {this.state.totalDocuments} -ből.</div>
                <table className="table table-striped table-bordered user-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th onClick={this.onSort('name')}>Dokumentum neve<span className={this.setArrow('name')}></span></th>
                            <th onClick={this.onSort('required_administration_time')}>Átfutási idő<span className={this.setArrow('required_administration_time')}></span></th>
                            <th >Állapot</th>                            
                            <th onClick={this.onSort('date')}>Dátum<span className={this.setArrow('date')}></span></th>
                            <th className="action-column"></th>
                        </tr>
                        <Filter filterElements={this.state.filterForm} change={this.inputChangeHandler} />
                    </thead>
                    {term.length ? documentListWithTerm : documentList}
                    {}
                </table>
                {term.length || !documentsArray.length ? null : 
                <div id="page-numbers">
                  <li ><i onClick={this.decreaseingToMinim} className="fa fa-angle-double-left"></i></li>
                    <li ><i onClick={this.decreaseing} style={{fontSize: '20', paddingTop: 3}} className="fa fa-angle-left"></i></li> 
                    {renderPageNumbers} 
                    <li><i onClick={this.increaseing} style={{fontSize: '20', paddingTop: 3}} className="fa fa-angle-right"></i></li>
                    <li ><i onClick={this.increaseingToMaxim} className="fa fa-angle-double-right"></i></li>
                </div>
                }
            </div>

        );
    }
}

export default Documents;