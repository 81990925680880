import React, {Component} from 'react';

class HomePage extends Component{
    componentWillMount(){
        window.location.href = `/#/users`;        
    }
    render(){
        return(
            <div></div>
        );
    }
}

export default HomePage;