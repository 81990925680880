import React, {Component} from 'react';
import Toolbar from '../../../components/Navigation/Toolbar/Toolbar';
import Aux from '../Auxx/Auxx';
import {connect} from 'react-redux';
import { TYPES } from '../../../store/actions';

class Layout extends Component{
    render(){
        return(
            <Aux>
                <Toolbar logoutFunction={this.props.onSetLoggedOut}/>
                <main>
                    {this.props.children}
                </main>
            </Aux>
        )
    }
}

const mapStateToProps = state => {
    return {
        login:state.userGroup.isLoggedIn
    }
}
const mapDispatchToProps = dispatch => {
    return{
        onSetLoggedOut: () =>dispatch({type:TYPES.SET_LOGGED_OUT}),
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Layout);