import React, {Component, useEffect, useState} from 'react'
import api from '../../../api/api';
import {NavLink} from 'react-router-dom'


let token = localStorage.getItem('token')
let userId = null;

class Documents extends Component{
    state = {
        userData:{
            firstname:{
                fieldName: 'Vezeték név',
                value:'',
            },
            lastName: {
                fieldName:'Kereszt név',
                value:''
            },
            email:{
                fieldName: 'E-mail',
                value: '',
            },
            role: {
                fieldName: 'Típus',
                value:''
            }
        },
        roleArray: [],
        formIsValid: true,
        saved:false,
    }

    componentWillMount(){
        this.getUserData();
        this.getRoles();
    }
    getUserData = () => {
        userId = this.props.match.params.id;
        api.get({url: 'users/'+userId},token).then(
            response => {
                const userData = {
                    ...this.state.userData
                };
                userData.email.value = response.data.email;
                userData.firstname.value = response.data.firstName;
                userData.lastName.value = response.data.lastName;
                userData.role.value = response.data.role === 'ADMIN' ? 'Admin' : 'Office';
                this.setState({ userData: userData});
            }
        ).catch(
            e => {
               console.log(e);
            }
        )
    }
    getRoles = () => {
        api.get({url: 'role/user/'+userId},token).then(
            response => {
                var roleArray = [];
                response.data.map(item => {
                    roleArray.push(item);
                })
                this.setState({roleArray: roleArray})
            }
        ).catch(
            e => {
               console.log(e);
            }
        )
    }
    onChangeHandler = (id) => {
        var oldArray = this.state.roleArray;
        oldArray.map(item => {
            if(item.id === id){
                if(item.chekcked === 0){
                    item.chekcked = 1;
                }
                else{
                    item.chekcked = 0;
                }
            }
        })
        const userData={
            user_id : userId,
            role_id : id
        }
        api.post({url: 'role/user', data: userData},token).then(
            response => {
                if(response.status === 201){
                    alert('Jogosultság módosítása sikeresen megtörtént!');
                }
            }
        ).catch(
            e => {
                console.log(e);
            }
        )
        this.setState({roleArray:oldArray});
    }

    render(){
        const user = this.state.userData;
        const roleArray = this.state.roleArray;
        const roles = roleArray.map(item=> {
            return <div key={item.id}><input type="checkbox" name="item.name" key={item.id} onChange={() => this.onChangeHandler(item.id)} value={1} checked={item.chekcked === 1 ? true: false}/> {item.name}</div>;
        })
    
        return(
            <div className="PageContent">
                <div className="userData">
                    <h3>Felhasználói adatok:</h3>
                    <p className="data-field"><b>{user.firstname.fieldName}</b> : {user.firstname.value}</p>
                    <p className="data-field"><b>{user.lastName.fieldName}</b> : {user.lastName.value}</p>
                    <p className="data-field"><b>{user.email.fieldName}</b> : {user.email.value}</p>
                    <p className="data-field"><b>{user.role.fieldName}</b> : {user.role.value}</p>
                </div>
                <hr />
                <div className="documentRendering">
                    <h3>Jogosultságok Kezelése:</h3>
                    {roles}
                </div>
                <NavLink to='/admin'  exact>
                    <span className="fa fa-arrow-left">Vissza</span>
                </NavLink>
            </div>
        )
    }
}

export default Documents