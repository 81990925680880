import { createStore, combineReducers } from 'redux';
import { testGroup, localeGroup, userGroup } from './reducers';

const rootReducer = combineReducers({
  testGroup,
  localeGroup,
  userGroup
});

export default createStore(rootReducer);
