import React ,{Component} from 'react';
import { Route, Switch } from 'react-router-dom'
import Login from '../../Pages/Login/Login';
import Users from '../../Pages/Users/Index';
import UserCreate from '../../Pages/Users/Create';
import UserUpdate from '../../Pages/Users/Update';
import UserView from '../../Pages/Users/View';
import errorView from '../../Pages/Error/index';
import empty from '../../Pages/Error/empty';
import HomePage from '../../Pages/HomePage/HomePage'
import Layout from '../../hoc/Layout/Layout';
import Monitoring from '../../Pages/Monitoring/index';
import Documents from '../../Pages/Documents/index';
import DocCreate from '../../Pages/Documents/Create';
import DocUpdate from '../../Pages/Documents/Update';
import UserDocs from '../../Pages/Users/Documents';
import Logs from '../../Pages/Logs/index';
import LogView from '../../Pages/Logs/View';
import Admin from '../../Pages/Admin/Index';
import Company from '../../Pages/Company/company';
import CompanyCreate from '../../Pages/Company/Create';
import CompanyUpdate from '../../Pages/Company/Update';
import CompanyView from '../../Pages/Company/View';
import CompanyCustomers from '../../Pages/Company/Customer';
import AdminCreate from '../../Pages/Admin/Create';
import AdminUpdate from '../../Pages/Admin/Update';
import Roles from '../../Pages/Admin/Roles';
import Jobs from '../../Pages/Jobs/index';
import JobsUpdate from '../../Pages/Jobs/update';




class Routers extends Component{
    render(){
      let routers=(
          <Layout>
                <Route path="/" exact component={HomePage} />
                <Route path="/users" exact component={Users} />
                <Route path="/companies" exact component={Company} />
                <Route path="/company/create" exact component={CompanyCreate} />
                <Route path="/company/update/:id" exact component={CompanyUpdate} />
                <Route path="/company/:id/users" exact component={CompanyCustomers} />
                <Route path="/company/view/:id" exact component={CompanyView} />
                <Route path="/admin" exact component={Admin} />
                <Route path="/users/create" exact component={UserCreate} />
                <Route path="/admin/create" exact component={AdminCreate} />
                <Route path="/users/update/:id" exact component={UserUpdate} />
                <Route path="/admin/update/:id" exact component={AdminUpdate} />
                <Route path="/users/view/:id" exact component={UserView} />
                <Route path="/users/documents/:id" exact component={UserDocs} />
                <Route path="/admin/roles/:id" exact component={Roles} />
                <Route path="/monitoring" exact component={Monitoring} />
                <Route path="/logs" exact component={Logs} />
                <Route path="/logs/view/:id" exact component={LogView} />
                <Route path="/documents" exact component={Documents} />
                <Route path="/jobs" exact component={Jobs} />
                <Route path="/jobs/update/:id" exact component={JobsUpdate} />
                <Route path="/documents/create" exact component={DocCreate} />
                <Route path="/document-types/update/:id" exact component={DocUpdate} />
                <Route path='/*' exact component={window.location.pathname == '/users' 
                                              || window.location.pathname == '/users/create' 
                                              || window.location.pathname == '/documents' 
                                              || (window.location.pathname).search('/users/update') > -1
                                              || (window.location.pathname).search('/users/documents') > -1
                                              || (window.location.pathname).search('/users/view/') > -1
                                              || (window.location.pathname).search('/monitoring') > -1
                                              || (window.location.pathname).search('/document-types/update') > -1
                                               ? empty : empty} />
          </Layout>
      )
      console.log(this.props.loginStatus)
        return(
            <Switch>
              <Route path="/login" exact component={Login} />
              {this.props.loginStatus ? routers: <Route path="/" component={Login} />}
          </Switch>
        );
    }
}

export default Routers;