export const isEmailValid = email => 
    {
        if (/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]+$/.test(email)) {
            return true;
        }
        else{
            return false;
        }
    }
export const lowerThenMaxLength = text =>{
  if(text.length < 256){
    return true;
  }
  else{
    return false;
  }
}
export const isNumber = number => {
    if(/^[0-9]\d*$/.test(number)){
      return true;
    }
    else{
      return false;
    }
}
export const isPhoneNumber = number => {
  if(/^[\d()\s-+]+$/.test(number)){
    return true;
  }
  else{
    return false;
  }
}

export const isCurrentDate = selectedDate => {
  if(selectedDate > new Date()){
    return true;
  }
  else{
    return false;
  }
}
export const checkPasswordLength = (password,rule) => {
  if(password.length >= rule)
  {
    return true;
  }
  else{
    return false;
  }
}
export const isPasswordValid = password =>
  password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,30}$/g);

export const isPhoneNumberValid = phone => {
  phone.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g);
};
export const isVideoValid = video => {
  if(video.type == "video/mp4"){
    return true;
  }
  else{
    return false;
  }
}
export const isImageValid = image => {
  if(image.type == "image/jpeg" || image.type == "image/png"){
    return true;
  }
  else{
    return false;
  }
}

export const slugify = str => {
  var map = {
    'a' : 'á|à|ã|â|À|Á|Ã|Â',
    'e' : 'é|è|ê|É|È|Ê',
    'i' : 'í|ì|î|Í|Ì|Î',
    'o' : 'ó|ò|ô|õ|Ó|Ò|Ô|Õ',
    'u' : 'ú|ù|û|ü|Ú|Ù|Û|Ü',
  };

  str = str.toLowerCase();

  for (var pattern in map) {
      str = str.replace(new RegExp(map[pattern], 'g'), pattern);
  };

  return str;
}
// export const isLoggedIn = () => {
//   const token = localStorage.getItem('token');
//   if (token) {
//     return true;
//   }
//   return false;
// };

export const getUserName = () => {
  const userData = JSON.parse(localStorage.getItem('user'));
  if (userData !== null) {
    return `${userData.first_name} ${userData.last_name}`;
  }
};

// export const getUserRole = () => {
//   const userData = JSON.parse(localStorage.getItem('user'));
//   if (userData !== null) {
//     return userData.roles[0];
//   }
// };

// export const logoutUser = () => {
//   if (localStorage.getItem('token') == null) return false;
//   if (localStorage.getItem('user') == null) return false;
//   localStorage.removeItem('token');
//   localStorage.removeItem('user');
//   window.location.reload('');
//   return true;
// };