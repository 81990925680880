import React, {Component, useEffect, useState} from 'react'
import api from '../../../api/api';
import {isCurrentDate} from '../../../Utils';
import moment from 'moment'
import DateTimePicker from 'react-datetime-picker';
import {isEmailValid, checkPasswordLength,isPhoneNumber,lowerThenMaxLength} from '../../../Utils';
import { isTemplateElement } from '@babel/types';


let token = localStorage.getItem('token')
let validationErrorFrom = '';
let clickNumber = 0;
let userId = null;

class Documents extends Component{
    state = {
        userData:{
            firstname:{
                fieldName: 'Vezeték név',
                value:'',
            },
            lastName: {
                fieldName:'Kereszt név',
                value:''
            },
            email:{
                fieldName: 'E-mail',
                value: '',
            },
            company:{
                fieldName: 'Foglalkoztató cég',
                value: '',
            },
            status: {
                fieldName: 'Állapot',
                value:''
            }
        },
        createForm: {
            docTypes: {
                id:'docTypes',
                elementType: 'select',
                elementConfig: {
                    type:'select',
                    options:[
                        {value: '0', displayValue:'Loading...'},
                    ]
                },
                value: '',
                validation: {
                    required: true,
                    maxLength:true,
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Dokuemntum Tipusa',
                valid: false,
                touched: false
            },
            date: {
                id:'date',
                elementType: 'dateTime',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: new Date(),
                returnedValue:new Date(),
                validation: {
                    required: true,
                    dateIsValid: true,
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Határidő',
                valid: true,
                touched: false
            },
        },
        documentArray: [],
        formIsValid: true,
        saved:false,
    }

    componentWillMount(){
        this.getUserData();
        this.getDocTypes();
        this.getDocArray();
    }
    getDocArray = () => {
        userId = this.props.match.params.id;
        api.get({url: 'user-documents/'+userId+'/all'},token).then(
            response => {
                let curentData = [];
                response.data.map(function(item){
                    let newData = {doctype :item.document_id, docName:item.document, date: item.expire_at, enabled:item.enabled, status: "old", id:item.id};
                    curentData = curentData.concat(newData);
                })
                this.setState({documentArray:curentData});
            }
        ).catch(
            e => {
               console.log(e);
            }
        )
    }

    getUserData = () => {
        userId = this.props.match.params.id;
        api.get({url: 'users/'+userId},token).then(
            response => {
                const userData = {
                    ...this.state.userData
                };
                userData.email.value = response.data.email;
                userData.firstname.value = response.data.firstName;
                userData.lastName.value = response.data.lastName;
                userData.company.value = response.data.company;
                userData.status.value = response.data.status === 'active' ? 'aktív' : 'inaktív';
                this.setState({ userData: userData});
            }
        ).catch(
            e => {
               console.log(e);
            }
        )
    }

    getDocTypes = () => {
        var selectData = [{value:0,displayValue:'Dokumentum kiválasztása'}];
        let token = localStorage.getItem('token')
        api.get({url: 'document-types'},token).then(
            response => {
                var resp = response.data
                for (let identifier in resp) {
                    selectData.push({value: resp[identifier].id, displayValue:resp[identifier].name})
                }
                const updatedForm = {
                    ...this.state.createForm
                };
                updatedForm.docTypes.elementConfig.options = selectData;
                updatedForm.docTypes.value = selectData[0].value;
                this.setState({ createForm: updatedForm});
                selectData = [];
            }
        ).catch(
            e => {
               console.log(e);
            }
        )
    }
    getDocType = (docId) => {
        let token = localStorage.getItem('token')
        api.get({url: 'document-types/'+docId},token).then(
            response => {
                this.setState({currentDocName:response.data.name});
            }
        ).catch(
            e => {
               console.log(e);
            }
        )
    }
    onSubmitHandler = () => {
        if(this.state.createForm.docTypes.valid === true && this.state.createForm.date.valid === true){
            let token = localStorage.getItem('token')
            let docId = parseInt(this.state.createForm.docTypes.value)
            const exists = this.state.documentArray.map(item => item.doctype === docId);
            const isOld = this.state.documentArray.map(item => { if(item.doctype === docId) return item.status});
            if(exists.indexOf(true) !== -1 && isOld[0] !== 'old') {
                alert('A kiválasztott dokumentum már hozzá lett rendelve a klienshez korábban.');
                return;
            } else {
                const {returnedValue: expire_at} = this.state.createForm.date;
                const {value: document} = this.state.createForm.docTypes;
                const userId = this.props.match.params.id;
                
                let userDocument = {
                    user_id: userId,
                    document_id: document,
                    expire_at: moment(expire_at).format("YYYY-MM-DD HH:mm:ss"),
                    enabled: 1,
                }
                api.post({url: 'user-documents/'+userId, data: userDocument},token).then(
                    response => {
                        if(response.status === 201){
                            alert('A kiválasztott dokumentum sikeresen hozzá lett rendelve a klienshez.');
                            window.location.reload();
                        }
                    }
                ).catch(
                    e => {
                        if(e.response.status === 409){
                            e.response.data.map(function(item){
                                if(item.document_id === parseInt(userDocument.document_id)){
                                    api.put({url: 'user-documents/'+item.id, data: userDocument},token).then(
                                        response => {
                                            if(response.status === 200){
                                                alert('A kiválasztott dokumentum sikeresen hozzá lett rendelve a klienshez.');
                                                window.location.reload();
                                            }
                                        }
                                    ).catch(
                                        e => {
                                            if(e.response.status == 409){
                                                this.setEmailConflictError()
                                            }
                                            else{
                                                console.log(e)
                                            }
                                        }
                                    )
                                }
                            })
                        }
                    }
                )            
            }
        }
    }
    inputChangeHandler = (event,inputIdentifier) => {
        const updatedForm = {
            ...this.state.createForm
        };
        const updatedFormElement = {
            ...updatedForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        if(updatedFormElement.elementConfig.type === 'checkbox'){
            updatedFormElement.value = !updatedFormElement.value
        }
        else{
            updatedFormElement.value = event.target.value;
        }
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        if (!updatedFormElement.valid) {
            updatedFormElement.validationMessage = this.setMessage(updatedFormElement.validation, updatedFormElement.labelName);
        }
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;


        let formIsValid = true;
        for (let inputIdentifier in updatedForm) {
            if(updatedForm[inputIdentifier].elementConfig.type != 'checkbox')
            formIsValid = updatedForm[inputIdentifier].valid && formIsValid ;
        }
        this.setState({ createForm: updatedForm, formIsValid: formIsValid });
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }
        if (rules.required && validationErrorFrom=='') {
            isValid = value.trim() !== '' && isValid;
            if(!isValid){
                validationErrorFrom = "required";
            }

        }
        if (rules.isEmail && validationErrorFrom==''){
            var localValidator = isEmailValid(value);
            isValid = localValidator && isValid;
            if(!isValid){
                validationErrorFrom = "isEmail";
            }
        }
        if(rules.minLength && validationErrorFrom == ''){
            var localValidator = checkPasswordLength(value,rules.minLength);
            isValid = localValidator && isValid;
            if(!isValid){
                validationErrorFrom = "minLength";
            }
        }
        
        return isValid;
    }

    setMessage(rules, label) {
        let message = '';
        if (!rules) {
            return '';
        }
        if (rules.required && validationErrorFrom == 'required') {
            message = [label, 'nem lehet üres!'].join(' ');
        }
        if (rules.isEmail && validationErrorFrom == 'isEmail') {
            message ='Helytelen e-mail cím!';
        }
        if (rules.minLength && validationErrorFrom == 'minLength') {
            message = ['A(z) ', label, ' legalább ', rules.minLength, ' karaktert kell tartalmazzon!'];
        }
        return message;
    }

    datepickerChangeHandler = (event) => {
        const updatedForm = {
            ...this.state.createForm
        }
        var startdate = moment(new Date(event));
        var formatedDate =startdate.add(3, 'hours')
        this.checkDateValidity(event)
        updatedForm.date.value=event;
        updatedForm.date.returnedValue = formatedDate._d;
        this.setState({createForm: updatedForm})
    }

    checkDateValidity(value){
        let isValid = true;
        if(validationErrorFrom == ''){
            var localValidator = isCurrentDate(value)
            isValid = localValidator && isValid;
            if(!isValid){
                validationErrorFrom = "isDateValid";
                const updatedForm = {
                    ...this.state.createForm
                }
                updatedForm.date.valid = false;
                updatedForm.date.validationMessage = this.setMessage(updatedForm.date.validation, updatedForm.date.labelName);
                updatedForm.date.touched = true;
                this.setState({createForm:updatedForm,formIsValid:false})
                
            }
            else{
                const updatedForm = {
                    ...this.state.createForm
                }
                updatedForm.date.valid = true;
                updatedForm.date.validationMessage ='';
                updatedForm.date.touched = true;
                this.setState({createForm:updatedForm,formIsValid:true})
            }
        }
    }

    setMessage(rules, label) {
        let message = '';
        if (!rules) {
            return '';
        }
        if (rules.required && validationErrorFrom == 'required') {
            message = [label, 'nem lehet üres!'].join(' ');
        }
        if (rules.dateIsValid && validationErrorFrom == 'isDateValid') {
            message =['A(z) ', label, ' csak jövőbeni értéket vehet fel!'];
        }
        return message;
    }

    onRemoveFromArray = (identifier) => {
        if(window.confirm('Biztos törölni szeretnéd?')) {
            var array = [...this.state.documentArray];
            const id = array[identifier].id;
            api.delete({url: 'user-documents/'+id, data: {}},token).then(
                response => {
                    array[identifier].enabled = 0;
                    this.setState({documentArray:array});
                    window.location.reload();
                }
            ).catch(err => console.log(err))
        }
    }

    render(){
        const user = this.state.userData
        const formElement = this.state.createForm
        var documentContainer= '<div></div>';
        const onRemoveFromArray = this.onRemoveFromArray;
        if(documentContainer.length > 0){
             documentContainer = this.state.documentArray.map(function(item, i){
                 if(item.enabled == 1){
                    var date =  moment(item.date).format('YYYY-MM-DD HH:mm:ss');
                    return(<div className="docElement" key={i}>
                        <span style={{fontSize: '15px'}}>{item.docName}</span> : <span style={{fontSize: '15px'}}>{date}</span> <button className="btn btn-sm btn-danger right-position" onClick={() => onRemoveFromArray(i)} key={i}>X</button>
                    </div>)
                 }
            })
        }
        return(
            <div className="PageContent">
                <div className="userData">
                    <h3>Felhasználói adatok:</h3>
                    <p className="data-field"><b>{user.firstname.fieldName}</b> : {user.firstname.value}</p>
                    <p className="data-field"><b>{user.lastName.fieldName}</b> : {user.lastName.value}</p>
                    <p className="data-field"><b>{user.email.fieldName}</b> : {user.email.value}</p>
                    <p className="data-field"><b>{user.status.fieldName}</b> : {user.status.value}</p>
                    <p className="data-field"><b>{user.company.fieldName}</b> : {user.company.value}</p>
                </div>
                <hr />
                <div className="documentRendering">
                    <h3>Dokumentum hozzáadása:</h3>
                    <select
                        value={formElement.docTypes.value}
                        className="form-control input-width left-position dropdown-height"
                        onChange={(event) => this.inputChangeHandler(event,this.state.createForm.docTypes.id)}>
                        {formElement.docTypes.elementConfig.options.map(option => (
                            <option key={option.value} 
                            value={option.value}>
                                {option.displayValue}
                            </option>
                        ))}
                    </select>
                    <DateTimePicker
                    onChange={(event) => this.datepickerChangeHandler(event)}
                    locale='hu-HU'
                    value={formElement.date.value}
                    calendarClassName="form-control input-width"
                    className="form-control input-width left-position"
                    />  
                    <button onClick={(event) => this.onSubmitHandler(event)} className="btn btn-success" id="add-documnt-to-list">+ Hozzáadás</button>
                </div>
                <hr />
                <div className="document-list">
                    {
                        documentContainer.length > 0 ? documentContainer : null
                    }                   
                </div>
            </div>
        )
    }
}

export default Documents