import React, {Component} from 'react';

class View extends Component{
    render(){
        const viewElementArray = [];
        for(let key in this.props.viewElements){
            viewElementArray.push({
                id:key,
                config:this.props.viewElements[key]
            });
        }
        let view = (
                <tbody>
                    {
                        viewElementArray.map(viewElement => (
                            <tr key={viewElement.id+ Math.random()}>
                                <th>{viewElement.config.labelName}</th>
                                <td className='viewTableStyle'>{viewElement.config.value}</td>
                            </tr>
                        ))
                    }
                </tbody>
        )
        return(
            <table id="w0" className="table table-striped table-bordered detail-view">
                {view}
            </table>
        );
    }
}

export default View;