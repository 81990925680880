export const TYPES = {
  SET_EXAMPLE_VALUE: 'SET_EXAMPLE_VALUE',
  SET_LOCALE: 'SET_LOCALE',
  SET_LOGGED_IN: 'SET_LOGGED_IN',
  SET_LOGGED_OUT: 'SET_LOGGED_OUT',
  GET_LOGIN_STATUS: 'GET_LOGIN_STATUS',
};

export const actions = {
  setExampleValue: text => ({ type: TYPES.SET_EXAMPLE_VALUE, text }),
  setLocale: locale => ({ type: TYPES.SET_LOCALE, locale }),
  setLoggedIn:() => ({types: TYPES.SET_LOGGED_IN}),
  setLoggedOut:() => ({types: TYPES.SET_LOGGED_OUT}),
  getLoginStatus:() => ({types: TYPES.GET_LOGIN_STATUS})
};
