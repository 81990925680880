import axios from "axios";
import config from "../config/config";

const api = {
  get(params, token) {
    if (token)
      axios.defaults.headers.common["Authorization"] = 'Bearer ' +localStorage.getItem(
        "token"
      );
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Accept"] = "application/json; charset=utf-8";
    axios.defaults.headers.common["Language"] = 'en';    
    return axios.get(`${config.ENDPOINT_BASE_URL}/${params.url}`);
  },

  post(params, token) {
    if (token) {
      axios.defaults.headers.common["Authorization"] ='Bearer ' + localStorage.getItem(
        "token"
      );

    }
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Accept"] = "application/json; charset=utf-8";
    axios.defaults.headers.common["Language"] = 'en';

    return axios.post(`${config.ENDPOINT_BASE_URL}/${params.url}`, params.data);
  },

  postFile(url, data) {
    axios.defaults.headers.common['Content-Type'] = 'application/json'
    return axios.put(url, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  putFile(params, token) {
    // axios.defaults.headers.common['Language'] = 'en';
    axios.defaults.headers.common["Content-Type"] = "multipart/form-data";
    if (token) axios.defaults.headers.common['Auth-Token'] = token;
      return axios.put(
        params.url,
        params.data,
      );
  },

  put(params, token) {
    if (token) {
      axios.defaults.headers.common["Authorization"] ='Bearer ' + localStorage.getItem(
        "token"
      );
    }
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Accept"] = "application/json; charset=utf-8";
    axios.defaults.headers.common["Language"] = 'en';    

    return axios.put(`${config.ENDPOINT_BASE_URL}/${params.url}`, params.data);
  },
  delete(params, token) {
    if (token) {
      axios.defaults.headers.common["Auth-Token"] = localStorage.getItem(
        "token"
      );
    }
    axios.defaults.headers.common["Content-Type"] = "application/json";
    axios.defaults.headers.common["Accept"] = "application/json; charset=utf-8";
    axios.defaults.headers.common["Language"] = 'en';    
    return axios.delete(
      `${config.ENDPOINT_BASE_URL}/${params.url}`,
      params.data
    );
  }
};
export default api;