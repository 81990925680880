import React, { Component } from 'react';
import Aux from '../../hoc/Auxx/Auxx';
import Input from '../../../components/UI/Input/Input';
import Button from '../../../components/UI/Button/Button';
import api from '../../../api/api';
import {connect} from 'react-redux';
import { TYPES } from '../../../store/actions';
import {isEmailValid} from '../../../Utils';


let validationErrorFrom = '';

class Login extends Component {

    state = {
        loginForm: {
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: ''
                },
                value: '',
                validation: {
                    required: true,
                    isEmail: true,
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'E-mail',
                valid: false,
                touched: false
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: '',
                },
                value: '',
                validation: {
                    required: true
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Jelszó',
                valid: false,
                touched: false
            }
        },
        formIsValid: false,
        bedData:false,
    }

    componentDidUpdate(){
        validationErrorFrom = '';
        if(this.props.login === true){
            window.location.href="/#/users"            
        }
    }
    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }
        if (rules.required && validationErrorFrom=='') {
            isValid = value.trim() !== '' && isValid;
            if(!isValid){
                validationErrorFrom = "required";
            }

        }
        if (rules.isEmail && validationErrorFrom==''){
            var localValidator = isEmailValid(value);
            isValid = localValidator && isValid;
            if(!isValid){
                validationErrorFrom = "isEmail";
            }
        }
        return isValid;
    }

    setMessage(rules, label) {
        let message = '';
        if (!rules) {
            return '';
        }
        if (rules.required && validationErrorFrom == 'required') {
            message = [label, 'nem lehet üres!'].join(' ');
        }
        if (rules.isEmail && validationErrorFrom == 'isEmail') {
            message ='Helytelen e-mail cím!';
        }
        return message;
    }

    inputChangeHandler = (event, inputIdentifier) => {
        if(this.state.bedData === true){
            const updatedLoginForm = {
                ...this.state.loginForm
            };
            updatedLoginForm.email.valid= true;
            this.setState({loginForm:updatedLoginForm, bedData: false});
        }
        const updatedLoginForm = {
            ...this.state.loginForm
        };
        const updatedFormElement = {
            ...updatedLoginForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        if (!updatedFormElement.valid) {
            updatedFormElement.validationMessage = this.setMessage(updatedFormElement.validation, updatedFormElement.labelName, updatedFormElement.value);
        }
        updatedFormElement.touched = true;
        updatedLoginForm[inputIdentifier] = updatedFormElement;


        let formIsValid = true;
        for (let inputIdentifier in updatedLoginForm) {
            formIsValid = updatedLoginForm[inputIdentifier].valid && formIsValid;
        }

        this.setState({ loginForm: updatedLoginForm, formIsValid: formIsValid });
    }

    setWrongDataError = () => {
        const updatedForm = {
            ...this.state.loginForm
        };
        const updatedFormElement = {
            ...updatedForm.email
        };
        updatedFormElement.validationMessage = "Helytelen e-mail cím vagy jelszó!";
        updatedFormElement.valid=false;
        updatedForm.email = updatedFormElement;
        this.setState({ loginForm: updatedForm, formIsValid: false, bedData:true });
    }

    submitHandler = (event) => {
        event.preventDefault();
        let userData = { email:this.state.loginForm.email.value, password: this.state.loginForm.password.value};
        if(this.state.formIsValid === true){
            api.post({url: 'login', data: userData},false).then(
                response => {
                    if(response.status === 200){
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('roles', response.data.roles);
                        localStorage.setItem('type', response.data.type);
                        if(response.data.token){
                            if(this.props.onSetLoggedIn()){
                                window.location.reload();
                            }
                        }
                    }
                }
            ).catch(
                e => {

                        console.log(e);
                    
                }
            )
        }
    }
    render() {
        const formElementArray = [];
        for (let key in this.state.loginForm) {
            formElementArray.push({
                id: key,
                config: this.state.loginForm[key]
            })
        }

        let form = (
            <form onSubmit={this.submitHandler}>
                {
                    formElementArray.map(formElement => (
                        <Input key={formElement.id}
                            className="form-control"
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                            invalid={!formElement.config.valid}
                            shouldValidate={formElement.config.validation}
                            message={formElement.config.validationMessage}
                            touched={formElement.config.touched}
                            hasLabel={formElement.config.hasLabel}
                            labelName={formElement.config.labelName}
                            changed={(event) => this.inputChangeHandler(event, formElement.id)}
                        />
                    ))
                }
                <Button btnType="btn btn-primary loginBtn" disabled={!this.state.formIsValid}>Bejelentkezés</Button>
            </form>


        )

        return (
            <Aux>
                <div className="LoginBg">
                    <div className="container loginPadding">
                        <div className="card mx-auto cardLogin">
                            <div className="card-header">
                                Bejelentkezés
                            </div>
                            <div className="card-body">
                                {form}
                            </div>
                        </div>
                    </div>
                </div>
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        login:state.userGroup.isLoggedIn
    }
}
const mapDispatchToProps = dispatch => {
    return{
        onSetLoggedIn: () =>dispatch({type:TYPES.SET_LOGGED_IN}),
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Login);