import React from 'react';
import Logo from '../../Logo/Logo'
import NavigationItems from '../NavigationItems/NavigationItems';
import Aux from '../../../containers/hoc/Auxx/Auxx';

const toolbar = (props) => (
    <Aux>
        <header className="Toolbar">
            <div className="Logo">
                <Logo />
            </div>
            <nav className="DesktopOnly">
                <NavigationItems logoutFunction={props.logoutFunction} />
            </nav>
        </header>
    </Aux>
);

export default toolbar