import React, {Component} from 'react'
import Input from '../Input/Input';

class filter extends Component{
    
    render(){
        const filterArray=[];
        for(let key in this.props.filterElements){
            filterArray.push({
                id:key,
                config:this.props.filterElements[key]
            });
        }
        let filters = (
            <tr id="w0-filters" className="filters">
                <td></td>
                {
                    filterArray.map(filter => (
                        <td key = {filter.id}>
                            <Input
                            myKey={filter.id}
                            className="form-control" 
                            elementType={filter.config.elementType}
                            elementConfig={filter.config.elementConfig}
                            value = {filter.config.value}
                            touched = {filter.config.touched}
                            hasLabel = {filter.config.hasLabel}
                            changed={(event) => this.props.change(event,filter.id)}
                            />
                        </td>
                      
                    ))
                }
            </tr>
        )
        return(
            {...filters}
        )
    }
}


export default filter;