import React, { Component }  from 'react';
import './App.css';
import Login from './containers/Pages/Login/Login';
import {connect} from 'react-redux';
import {TYPES} from './store/actions';
import { HashRouter} from 'react-router-dom'
import Routers from './containers/hoc/Routers/Routers';


class App extends Component {
  componentWillMount(){
    if(localStorage.getItem('token')){
      this.props.onSetLoggedIn();
    }
  }
  
  render(){
    return (
      <HashRouter>
        <Routers loginStatus={this.props.login} />
      </HashRouter>
    );
  }
 
}

const mapStateToProps = state => {
  return {
      login:state.userGroup.isLoggedIn
  }
}
const mapDispatchToProps = dispatch => {
  return{
      onSetLoggedIn: () =>dispatch({type:TYPES.SET_LOGGED_IN}),
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);
