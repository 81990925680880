import React, { Component } from 'react';
import Title from '../../../components/UI/Title/Title';


class index extends Component {
    render() {
        return (
            <div className="PageContent">
                <Title>Nincs ilyen oldal!</Title>
            </div>
        );
    }
}
export default index;