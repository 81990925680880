import React, { Component } from 'react';
import Title from '../../../components/UI/Title/Title';


class empty extends Component {
    render() {
        return (
            <div >

            </div>
        );
    }
}
export default empty;