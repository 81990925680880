import React, {Component } from 'react';
import Input from '../UI/Input/Input';
import Button from '../UI/Button/Button';
import AUX from '../../containers/hoc/Auxx/Auxx';

class Forms extends Component{
    
    render(){
        const formElementArray = [];
        for(let key in this.props.formElements){
            formElementArray.push({
                id:key,
                config:this.props.formElements[key]
            });
        }
        let form = (
            <form onSubmit={this.props.submitHandler}>
                {
                    formElementArray.map(formElement => {
                        if(formElement.id == 'imageView'){
                            return <img key={formElement.id} style={{maxHeight: 200}} src={formElement.config.value} ></img>
                        }
                        else{
                            if(formElement.id == 'youtubeEmbeddedLink'){
                                return(
                                <AUX>
                                <iframe  key={formElement.id} src={['https://www.youtube.com/embed/',formElement.config.value,'?autoplay=1'].join('')} width="540" height="450"></iframe>
                                <a key={formElement.id+1} style={{display: "block"}} target="_blank" href={formElement.config.link}>{formElement.config.link}</a>
                                </AUX>
                                )
                            }
                            else if(formElement.id =='facebookUrl'){
                                return <a key={formElement.id} target="_blank" style={{display: "block"}} href={formElement.config.link}>{formElement.config.link}</a>
                            }
                            else{
                                return <Input key={formElement.id}
                                        acceptedFileFormat = {formElement.config.acceptedFileFormat ? formElement.config.acceptedFileFormat: null}
                                        dateChanged = {(date) => this.props.dateChanged(date)}
                                        showProperty = {this.props.showElementProp ? this.props.showElementProp : false}
                                        checkboxProperty = {formElement.config.elementConfig.type === 'checkbox'? formElement.config.checked : null}
                                        className={formElement.config.elementConfig.type === 'checkbox' ? 'noClass' : "form-control"} 
                                        elementType={formElement.config.elementType}
                                        elementConfig={formElement.config.elementConfig}
                                        value = {formElement.config.value}
                                        invalid={!formElement.config.valid}
                                        shouldValidate={formElement.config.validation}
                                        message = {formElement.config.validationMessage}
                                        touched = {formElement.config.touched}
                                        hasLabel = {formElement.config.hasLabel}
                                        labelName = {formElement.config.labelName}
                                        changed={(event) => this.props.changeHandler(event,formElement.id)}
                                        />
                            }
                        }
                      
                    }
                        
                       
                    )
                }
                <Button btnType={"btn btn-primary"} disabled={!this.props.formIsValid}>Mentés</Button>
            </form>
        )
        return(
            <div>
                {form}
            </div>
        )
    }
}

export default Forms;