import React, { Component } from 'react';
import Title from '../../../components/UI/Title/Title';
import CreateBtn from '../../../components/Navigation/PageNavigationItems/CreateNavigationItem';
import Filter from '../../../components/UI/Filter/Filter';
import api from '../../../api/api';
import {ViewEye} from '../../../components/UI/ActionItems/ActionItems';
import * as Constants from '../../../constant';
import {slugify} from '../../../Utils';

let documentList = null;
let token = localStorage.getItem('token');
let roles = localStorage.getItem('roles');


class Customer extends Component {
    state = {
        customersArray:[],
        totalCustomers:0,
        currentPage: 1,
        deleted:false,
        minPageLimit:1,
        maxPageLimit:3,
        difference:2,
        documentsPerPage: Constants.documentsPerPage,
        term:'',
        activeInput: '',
        sort: {
            column: null,
            direction: 'desc',
          },
        filterForm: {
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                hasLabel: false,
                touched: false
            },
            firstName: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                hasLabel: false,
                touched: false
            },
            lastName: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                hasLabel: false,
                touched: false
            },
         
        },
    }

    componentDidMount(){
      this.getDataFromServer();
    }

    getDataFromServer = () => {
      const {id: companyId} = this.props.match.params
      api.get({url: `companies/${companyId}/users`},token).then(
        response => {
            this.setState({customersArray:response.data})
            this.setState({totalCustomers:response.data.length})

        }
    ).catch(
        e => {
           console.log(e);
        }
    )
    }

    inputChangeHandler = (event, inputIdentifier) => {
        
      const updatedFilter = {
          ...this.state.filterForm
      };
      const updatedFilterElement = {
          ...updatedFilter[inputIdentifier]
      };
      updatedFilterElement.value = event.target.value;
      updatedFilterElement.touched = true;
      updatedFilter[inputIdentifier] = updatedFilterElement;


      this.setState({ filterForm: updatedFilter, term: event.target.value, activeInput:inputIdentifier});
  }

    onSort = (column) => (e) => {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const sortedData = this.state.customersArray.sort((a, b) => {
          if (column === Constants.name) {
            const nameA = slugify(a.name.trim().toUpperCase()); // ignore upper and lowercase
            const nameB = slugify(b.name.trim().toUpperCase()); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          } else {
            const nameA = (a.created_at); // ignore upper and lowercase
            const nameB = (a.created_at); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          }
        });
          
        if (direction === 'desc') {
          sortedData.reverse();
        }
        
        this.setState({
          data: sortedData,
          sort: {
            column,
            direction,
          }
        });
      };

    setArrow = (column) => {
    let className = 'sort-direction';
    
    if (this.state.sort.column === column) {
        className += this.state.sort.direction === 'asc' ? ' fa fa-arrow-up' : ' fa fa-arrow-down';
    }
    return className;
    };

    currentPageHandler = (e) => {
        this.setState({
          currentPage: Number(e.target.id)
        });
      }

    searchingFor = (term,identifier) => {
    return function(document) {
      if(identifier == 'status'){
        return (identifier!= '' && String(document.enabled)== term) || !term;
      }
      // else if(identifier == 'date'){
      //   return (identifier!= '' && (document.created_at ? document.created_at : '0').includes(term)) || !term;
      // }
      else{
          return (identifier!= '' && document[identifier].toLowerCase().includes(term.toLowerCase())) || !term;
      }
    }
    }

    componentDidUpdate(){
      if(this.state.deleted == true){
        setTimeout(() => {
          this.getDataFromServer();
          this.setState({deleted:false})
        }, 150);
        
        
      }
    }

    changeData = () => {
      this.setState({deleted:true})
    }

    increaseing = () => {
      if(this.state.maxPageLimit < Math.ceil(this.state.customersArray.length / this.state.documentsPerPage)){
          this.setState({ maxPageLimit: this.state.maxPageLimit+1, minPageLimit:this.state.minPageLimit+1});
      }
    }

    decreaseing = () => {
      if(this.state.minPageLimit > 1){
          this.setState({ maxPageLimit: this.state.maxPageLimit-1, minPageLimit:this.state.minPageLimit-1});
      }
    }
    decreaseingToMinim = () => {
      this.setState({ maxPageLimit: this.state.difference+1, minPageLimit:1});
    }

    increaseingToMaxim = () => {
      this.setState({ maxPageLimit: Math.ceil(this.state.customersArray.length / this.state.documentsPerPage), minPageLimit:Math.ceil(this.state.customersArray.length / this.state.documentsPerPage)-this.state.difference});
    }


    render() {
        const { customersArray, term, currentPage,  documentsPerPage } = this.state;
        const indexOfLastDocument = currentPage * documentsPerPage;
        const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
        const currentDocuments = this.state.customersArray.length ? this.state.customersArray.slice(indexOfFirstDocument, indexOfLastDocument) : null;
        const documentListWithTerm = this.state.customersArray.length ? (
            <tbody>
                {
                    
                customersArray.filter(this.searchingFor(term,this.state.activeInput)).map(customer => {
                return (
                  <tr key={customer.id + Math.random()} data-key={customer.id}>
                    <td>{customer.id}</td>
                    <td >{customer.email}</td>                    
                    <td>{customer.firstName}</td>
                    <td>{customer.lastName}</td>
                    <td onClick={this.changeData} id={customer.id} >                      
                    {roles && roles.search('VIEW')>-1 && <ViewEye link={"/users/update/"+customer.id} />}
                    </td>
                  </tr>
                ) 
                })
                }
            </tbody>
            
          ) : (
            <tbody>
                <tr><th>Ehez a céghez nem tartozik ügyfél.</th></tr>
            </tbody>
          )
        documentList = currentDocuments ? (
            <tbody>
                {
                    currentDocuments.map(customer => (
                        <tr key={customer.id + Math.random()} data-key={customer.id}>
                        <td>{customer.id}</td>
                        <td >{customer.email}</td>                    
                        <td>{customer.firstName}</td>
                        <td>{customer.lastName}</td>
                        <td onClick={this.changeData} id={customer.id} >                      
                        {roles && roles.search('VIEW')>-1 && <ViewEye link={"/users/update/"+customer.id} />}
                        </td>
                      </tr>
                    ))
            
                }
            </tbody>
        ) : (
            <tbody>
                <tr><th>Ehez a céghez nem tartozik ügyfél.</th></tr>
            </tbody>
          );

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(customersArray.length / documentsPerPage); i++) {
          if(i >= this.state.minPageLimit && i <= this.state.maxPageLimit)
            pageNumbers.push(i);
        }
        
        const renderPageNumbers = pageNumbers.map(number => {
            return (
              <li
                key={number}
                id={number}
                onClick={this.currentPageHandler}
              >
                {number}
              </li>
            );
          });
        
        return (
            <div className="PageContent">
                <Title >Alkalmazottak</Title>                
                <div className="summary">Megjelenik {this.state.totalCustomers <documentsPerPage ? this.state.totalCustomers : documentsPerPage} a(z) {this.state.totalCustomers} -ből.</div>
                <table className="table table-striped table-bordered user-table">
                    {documentList.length && <thead>
                        <tr>
                            <th>#</th>
                            <th onClick={this.onSort('email')}>Email<span className={this.setArrow('email')}></span></th>
                            <th onClick={this.onSort('firstName')}>Vezeték név<span className={this.setArrow('firstName')}></span></th>
                            <th onClick={this.onSort('lastName')}>Kereszt név<span className={this.setArrow('lastName')}></span></th>
                            <th className="action-column"></th>
                        </tr>
                        <Filter filterElements={this.state.filterForm} change={this.inputChangeHandler} />
                    </thead>}
                    {term.length ? documentListWithTerm : documentList}
                    {}
                </table>
                {term.length || !documentList.length ? null : 
                <div id="page-numbers">
                  <li ><i onClick={this.decreaseingToMinim} className="fa fa-angle-double-left"></i></li>
                    <li ><i onClick={this.decreaseing} style={{fontSize: '20', paddingTop: 3}} className="fa fa-angle-left"></i></li> 
                    {renderPageNumbers} 
                    <li><i onClick={this.increaseing} style={{fontSize: '20', paddingTop: 3}} className="fa fa-angle-right"></i></li>
                    <li ><i onClick={this.increaseingToMaxim} className="fa fa-angle-double-right"></i></li>
                </div>
                }
            </div>

        );
    }
}

export default Customer;