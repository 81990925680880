import React, { Component } from 'react';
import Title from '../../../components/UI/Title/Title';
import CreateBtn from '../../../components/Navigation/PageNavigationItems/CreateNavigationItem';
import Filter from '../../../components/UI/Filter/Filter';
import api from '../../../api/api';
import {ViewEye, DocumentRender, DeleteTrash} from '../../../components/UI/ActionItems/ActionItems';
import * as Constants from '../../../constant';
import {slugify} from '../../../Utils';

//let loggingArray= [];
let loggingList = null;
let token = localStorage.getItem('token');
let roles = localStorage.getItem('roles');

class Logging extends Component {
    state = {
        loggingArray:[],
        totalLogging:0,
        currentPage: 1,
        deleted:false,
        minPageLimit:1,
        maxPageLimit:3,
        difference:2,
        loggingPerPage: Constants.usersPerPage,
        term:'',
        activeInput: '',
        sort: {
            column: null,
            direction: 'desc',
          },
        filterForm: {
            user: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                hasLabel: false,
                touched: false
            },
            operation: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                hasLabel: false,
                touched: false
            },
        },
    }

    componentDidMount(){
      this.getDataFromServer();
    }

    getDataFromServer = () => {
      api.get({url: 'log'},token).then(
        response => {
            this.setState({loggingArray:response.data})
            this.setState({totalLogging:response.data.length})

        }
    ).catch(
        e => {
           console.log(e);
        }
    )
    }

    inputChangeHandler = (event, inputIdentifier) => {
        
      const updatedFilter = {
          ...this.state.filterForm
      };
      const updatedFilterElement = {
          ...updatedFilter[inputIdentifier]
      };
      updatedFilterElement.value = event.target.value;
      updatedFilterElement.touched = true;
      updatedFilter[inputIdentifier] = updatedFilterElement;


      this.setState({ filterForm: updatedFilter, term: event.target.value, activeInput:inputIdentifier});
  }

    onSort = (column) => (e) => {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const sortedData = this.state.loggingArray.sort((a, b) => {
          if (column === Constants.user) {
            const nameA = slugify(a.user.trim().toUpperCase()); // ignore upper and lowercase
            const nameB = slugify(b.user.trim().toUpperCase()); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          } else if (column === Constants.operation) {
            let nameA = slugify(a.operation.trim().toLowerCase()); // ignore upper and lowercase
            let nameB = slugify(b.operation.trim().toLowerCase());
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
    
          } else {
            const nameA = slugify(a.expire_at.trim().toUpperCase()); // ignore upper and lowercase
            const nameB = slugify(b.expire_at.trim().toUpperCase()); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          }
        });
          
        if (direction === 'desc') {
          sortedData.reverse();
        }
        
        this.setState({
          data: sortedData,
          sort: {
            column,
            direction,
          }
        });
      };

    setArrow = (column) => {
    let className = 'sort-direction';
    
    if (this.state.sort.column === column) {
        className += this.state.sort.direction === 'asc' ? ' fa fa-arrow-up' : ' fa fa-arrow-down';
    }
    return className;
    };

    currentPageHandler = (e) => {
        this.setState({
          currentPage: Number(e.target.id)
        });
      }

    searchingFor = (term,identifier) => {
    return function(data) {
      if(identifier === 'enabled'){
        return (identifier!== '' && String(data.enabled)=== term) || !term;
      }
      else if(identifier == 'role'){
        return (identifier!= '' && String(data.role)== term) || !term;
      }
      else{
          return (identifier!= '' && data[identifier].toLowerCase().includes(term.toLowerCase())) || !term;
      }
    }
    }

    componentDidUpdate(){
      if(this.state.deleted == true){
        // this.setState({loggingArray:null})
        setTimeout(() => {
          this.getDataFromServer();
          this.setState({deleted:false})
        }, 150);
        
        
      }
    }

    changeData = () => {
      this.setState({deleted:true})
    }

    increaseing = () => {
      if(this.state.maxPageLimit < Math.ceil(this.state.loggingArray.length / this.state.loggingPerPage)){
          this.setState({ maxPageLimit: this.state.maxPageLimit+1, minPageLimit:this.state.minPageLimit+1});
      }
    }

    decreaseing = () => {
      if(this.state.minPageLimit > 1){
          this.setState({ maxPageLimit: this.state.maxPageLimit-1, minPageLimit:this.state.minPageLimit-1});
      }
    }
    decreaseingToMinim = () => {
      this.setState({ maxPageLimit: this.state.difference+1, minPageLimit:1});
    }

    increaseingToMaxim = () => {
      this.setState({ maxPageLimit: Math.ceil(this.state.loggingArray.length / this.state.loggingPerPage), minPageLimit:Math.ceil(this.state.loggingArray.length / this.state.loggingPerPage)-this.state.difference});
    }


    render() {
        const { loggingArray, term, currentPage, loggingPerPage } = this.state;
        const indexOfLastLogging = currentPage * loggingPerPage;
        const indexOfFirstLogging = indexOfLastLogging - loggingPerPage;
        const currentLogging = this.state.loggingArray.length ? this.state.loggingArray.slice(indexOfFirstLogging, indexOfLastLogging) : null;
        const loggingListWithTerm = this.state.loggingArray.length ? (
            <tbody>
                {
                loggingArray.filter(this.searchingFor(term,this.state.activeInput)).map(data => {
                return (
                  <tr key={data.id + Math.random()} className={data.color} data-key={data.id}>
                    <td>{data.id}</td>
                    <td >{data.user}</td>
                    <td>{data.operation}</td>
                    <td onClick={this.changeData} id={data.id} >
                         {/* <UpdatePencil link={"/monitoring/update/"+data.id}/> */}
                        {roles && roles.search('VIEW')>-1 && <ViewEye link={"/logs/view/"+data.id} />}
                        {/* <DocumentRender link={"/monitoring/documents/"+data.id}/>
                        <DeleteTrash  link={"monitoring/"+data.id}/>  */}
                    </td>
                  </tr>
                ) 
                })
                }
            </tbody>
            
          ) : (
            <tbody>
                <tr><th>Loading Logs...</th></tr>
            </tbody>
          )
        loggingList = currentLogging ? (
            <tbody>
                {
                    currentLogging.map(data => (
                        <tr key={data.id + Math.random()} className={data.color} data-key={data.id}>
                            <td>{data.id}</td>
                            <td >{data.user}</td>
                            <td>{data.operation}</td>
                            <td onClick={this.changeData} id={data.id} >
                                {roles && roles.search('VIEW')>-1 && <ViewEye link={"/logs/view/"+data.id} />}
                                {/* <UpdatePencil link={"/monitoring/update/"+data.id}/>
                                <DocumentRender link={"/monitoring/documents/"+data.id}/>
                                <DeleteTrash link={"monitoring/"+data.id}/> */}
                            </td>
                        </tr>
                    ))
            
                }
            </tbody>
        ) : (
            <tbody>
                <tr><th>Loading Logs...</th></tr>
            </tbody>
          );

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(loggingArray.length / loggingPerPage); i++) {
          if(i >= this.state.minPageLimit && i <= this.state.maxPageLimit)
            pageNumbers.push(i);
        }
        
        const renderPageNumbers = pageNumbers.map(number => {
            return (
              <li
                key={number}
                id={number}
                onClick={this.currentPageHandler}
              >
                {number}
              </li>
            );
          });
        
        return (
            <div className="PageContent">
                <Title >Logok</Title>
                <div className="summary">Megjelenik {this.state.totalLogging <loggingPerPage ? this.state.totalLogging : loggingPerPage} a(z) {this.state.totalLogging} -ből.</div>
                <table className="table table-striped table-bordered user-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th onClick={this.onSort('user')}>Felhasználó<span className={this.setArrow('user')}></span></th>
                            <th onClick={this.onSort('operation')}>Művelet<span className={this.setArrow('operation')}></span></th>
                            <th className="action-column"></th>
                        </tr>
                        <Filter filterElements={this.state.filterForm} change={this.inputChangeHandler} />
                    </thead>
                    {term.length ? loggingListWithTerm : loggingList}
                    {}
                </table>
                {term.length ? null : 
                <div id="page-numbers">
                  <li ><i onClick={this.decreaseingToMinim} className="fa fa-angle-double-left"></i></li>
                    <li ><i onClick={this.decreaseing} style={{fontSize: '20', paddingTop: 3}} className="fa fa-angle-left"></i></li> 
                    {renderPageNumbers} 
                    <li><i onClick={this.increaseing} style={{fontSize: '20', paddingTop: 3}} className="fa fa-angle-right"></i></li>
                    <li ><i onClick={this.increaseingToMaxim} className="fa fa-angle-double-right"></i></li>
                </div>
                }
            </div>

        );
    }
}

export default Logging;