import React, { Component } from 'react';
import ViewComponent from '../../../components/UI/Views/Views';
import Title from '../../../components/UI/Title/Title';
import api from '../../../api/api';
import {NavLink} from 'react-router-dom'


class View extends Component {
    state = {
        viewElements: {
            firstname: {
                value: '',
                labelName: 'Vezetéknév',
            },
            lastname: {
                value: '',
                labelName: 'Keresztnév',
            },
            email: {
                value: '',
                labelName: 'E-mail',
            },
            company: {
                value: '',
                labelName: 'Foglalkoztató cég',
            },
            role:{
                value:'',
                labelName:'Felhasználó típusa'
            },
            status:{
                value:'',
                labelName:'Állapot'
            }        
          
          
        },
    }

    componentWillMount(){
      this.getDataFromServer();
    }

    getDataFromServer = () => {
        const {id: userId} = this.props.match.params
        let token = localStorage.getItem('token')
        api.get({url: 'users/'+userId},token).then(
            response => {
                const viewForm = {
                    ...this.state.viewElements
                };
                viewForm.email.value = response.data.email;
                viewForm.role.value = response.data.role;
                viewForm.firstname.value = response.data.firstName;
                viewForm.lastname.value = response.data.lastName;
                viewForm.status.value = response.data.status;
                viewForm.company.value = response.data.company;
               
                this.setState({ viewElements: viewForm});
            }
        ).catch(
            e => {
               console.log(e);
            }
        )
    }

    render() {
 
        return (
            <div className="PageContent">
                <Title>Felhasználó</Title>
                <ViewComponent viewElements={this.state.viewElements} />
                <NavLink to='/users'  exact>
                    <span className="fa fa-arrow-left">Vissza</span>
                </NavLink>
            </div>
        );
    }
}

export default View;