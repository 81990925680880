import React from 'react';
import {Link} from 'react-router-dom'
import api from '../../../api/api';

export const UpdatePencil = (props) => (
    <Link to={props.link}  exact={props.exact}>
        <span title="Szerkesztés" className="fa fa-pencil"></span>
    </Link>
)
export const ViewEye = (props) => (
    <Link to={props.link} exact={props.exact}>
        <span title="Részletek" className="fa fa-eye"></span>
    </Link>
)
export const DocumentRender = (props) => (
    <Link to={props.link} exact={props.exact}>
        <span title="Dokumentumok" className="fa fa-file-text"></span>
    </Link>
)
const onClickHandler = (link) => {
    if(window.confirm("Biztos törölni szeretnéd?")) {
        if(link){
            var token = localStorage.getItem('token')
            api.delete({url: link},token).then(
                response => {
                    if(response.status === 200){
                        // window.location.reload();
                    }
                }
            ).catch(
                e => {
                        console.log(e)
                }
            )
        }    
    }
}
export const DeleteTrash = (props) => {

    return(
            <span title="Törlés" onClick={() => onClickHandler(props.link)} className="fa fa-trash"></span>
    )
        
}
export const UsersList = (props) => {

    return(
        <Link to={props.link} exact={props.exact}>
        <span title="Alkalmazottak" className="fa fa-user"></span>
    </Link>
            // <span title="Alkalmazottak" onClick={() => onClickHandler(props.link)} className="fa fa-user"></span>
    )
        
}

