import React, { Component } from 'react';
import ViewComponent from '../../../components/UI/Views/Views';
import Title from '../../../components/UI/Title/Title';
import api from '../../../api/api';
import {NavLink} from 'react-router-dom'


class View extends Component {
    state = {
        viewElements: {
            name: {
                value: '',
                labelName: 'Megnevezés',
            },
            tax_number: {
                value: '',
                labelName: 'Adószám',
            },
            registration_number: {
                value: '',
                labelName: 'Cégjegyzék szám',
            },
            enabled:{
                value:'',
                labelName:'Engedélyezett'
            }
          
        },
    }

    componentWillMount(){
      this.getDataFromServer();
    }

    getDataFromServer = () => {
        const {id: companyId} = this.props.match.params
        let token = localStorage.getItem('token')
        api.get({url: 'companies/'+companyId},token).then(
            response => {
                const viewForm = {
                    ...this.state.viewElements
                };
                viewForm.name.value = response.data.name;
                viewForm.registration_number.value = response.data.registration_number;
                viewForm.tax_number.value = response.data.tax_number;
                viewForm.enabled.value = response.data.enabled === 1 ? 'Igen' : 'Nem';
                this.setState({ viewElements: viewForm});
            }
        ).catch(
            e => {
               console.log(e);
            }
        )
    }

    render() {

        return (
            <div className="PageContent">
                <Title>Cég</Title>
                <ViewComponent viewElements={this.state.viewElements} />
                <NavLink to='/companies'  exact>
                    <span className="fa fa-arrow-left">Vissza</span>
                </NavLink>
            </div>
        );
    }
}

export default View;