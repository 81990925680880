import React, { Component } from 'react';
import Title from '../../../components/UI/Title/Title';
import Form from '../../../components/Forms/Forms';
import api from '../../../api/api';
import {isEmailValid, checkPasswordLength,isPhoneNumber,lowerThenMaxLength} from '../../../Utils';


let validationErrorFrom = '';
let clickNumber = 0;

class Create extends Component {
    state = {
        createForm: {
            firstname: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                validation: {
                    required: true,
                    maxLength:true,
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Vezetéknév',
                valid: false,
                touched: false
            },
            lastname: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                validation: {
                    required: true,
                    maxLength:true,
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Keresztnév',
                valid: false,
                touched: false
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: ''
                },
                value: '',
                validation: {
                    required: true,
                    isEmail:true
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'E-mail',
                valid: false,
                touched: false
            },
            country: {
                elementType: 'select',
                elementConfig: {
                    options:[
                        {value: '0', displayValue:'Loading...'},
                    ]
                },
                value: '',
                validation: {
                    required: true
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Ország',
                valid: true,
            },
            company_id: {
                elementType: 'select',
                elementConfig: {
                    options:[
                        {value: '0', displayValue:'Loading...'},
                    ]
                },
                value: '',
                validation: {
                    required: true
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Foglalkoztató cég',
                valid: true,
            },
            address: {
                elementType: 'textarea',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                validation: {
                    required: false,
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Lakcím',
                valid: true,
                touched: false
            },
            status: {
                elementType: 'select',
                elementConfig: {
                    options:[
                        {value: 'inactive', displayValue:'Inaktív'},
                        {value: 'active', displayValue:'Aktív'},
                    ]
                },
                value: 'inactive',
                validation: {
                    required: true
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Állapot',
                valid: true,
            }
        },
        formIsValid: false,
        saved:false,
    }

    componentWillMount(){
        this.getCountries();
        this.getCompanies();
       
    }
    getCompanies = () => {
        var selectData = [];
        let token = localStorage.getItem('token')
        api.get({url: 'companies'},token).then(
            response => {
                var resp = response.data
                for (let identifier in resp) {
                    selectData.push({value: resp[identifier].id, displayValue:resp[identifier].name})
                }
                const updatedForm = {
                    ...this.state.createForm
                };
                updatedForm.company_id.elementConfig.options = selectData;
                updatedForm.company_id.value = selectData[0].value;
                this.setState({ createForm: updatedForm});
                selectData = [];
            }
        ).catch(
            e => {
               console.log(e);
            }
        )

    }
    getCountries = () => {
        var selectData = [];
        let token = localStorage.getItem('token')
        api.get({url: 'countries'},token).then(
            response => {
                var resp = response.data
                for (let identifier in resp) {
                    selectData.push({value: resp[identifier].id, displayValue:resp[identifier].name})
                }
                const updatedForm = {
                    ...this.state.createForm
                };
                updatedForm.country.elementConfig.options = selectData;
                updatedForm.country.value = selectData[0].value;
                this.setState({ createForm: updatedForm});
                selectData = [];
            }
        ).catch(
            e => {
               console.log(e);
            }
        )

    }


    componentDidUpdate(){
        validationErrorFrom = '';
    }

    inputChangeHandler = (event, inputIdentifier) => {

        const updatedForm = {
            ...this.state.createForm
        };
        const updatedFormElement = {
            ...updatedForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        if(updatedFormElement.elementConfig.type === 'checkbox'){
            updatedFormElement.value = !updatedFormElement.value
        }
        else{
            updatedFormElement.value = event.target.value;
        }
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        if (!updatedFormElement.valid) {
            updatedFormElement.validationMessage = this.setMessage(updatedFormElement.validation, updatedFormElement.labelName);
        }
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;


        let formIsValid = true;
        for (let inputIdentifier in updatedForm) {
            if(updatedForm[inputIdentifier].elementConfig.type != 'checkbox')
            formIsValid = updatedForm[inputIdentifier].valid && formIsValid ;
        }
        this.setState({ createForm: updatedForm, formIsValid: formIsValid });
    }

    setEmailConflictError = () => {
        const updatedForm = {
            ...this.state.createForm
        };
        const updatedFormElement = {
            ...updatedForm.email
        };
        updatedFormElement.validationMessage = "A megadott e-mail már használatban van";
        updatedFormElement.valid=false;
        updatedForm.email = updatedFormElement;
        this.setState({ createForm: updatedForm, formIsValid: false });
    }


    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }
        if (rules.required && validationErrorFrom=='') {
            isValid = value.trim() !== '' && isValid;
            if(!isValid){
                validationErrorFrom = "required";
            }

        }
        if (rules.isEmail && validationErrorFrom==''){
            var localValidator = isEmailValid(value);
            isValid = localValidator && isValid;
            if(!isValid){
                validationErrorFrom = "isEmail";
            }
        }
        if(rules.minLength && validationErrorFrom == ''){
            var localValidator = checkPasswordLength(value,rules.minLength);
            isValid = localValidator && isValid;
            if(!isValid){
                validationErrorFrom = "minLength";
            }
        }
        
        return isValid;
    }

    setMessage(rules, label) {
        let message = '';
        if (!rules) {
            return '';
        }
        if (rules.required && validationErrorFrom == 'required') {
            message = [label, 'nem lehet üres!'].join(' ');
        }
        if (rules.isEmail && validationErrorFrom == 'isEmail') {
            message ='Helytelen e-mail cím!';
        }
        if (rules.minLength && validationErrorFrom == 'minLength') {
            message = ['A(z) ', label, ' legalább ', rules.minLength, ' karaktert kell tartalmazzon!'];
        }
        return message;
    }

    onSubmitHandler = (event) => {
        if(clickNumber == 0){
            clickNumber =1;
            event.preventDefault();
            let userData = { 
                email:this.state.createForm.email.value, 
                firstName: this.state.createForm.firstname.value,
                lastName: this.state.createForm.lastname.value,
                countryId:parseInt(this.state.createForm.country.value),
                address:this.state.createForm.address.value,
                status:this.state.createForm.status.value,
                company_id:this.state.createForm.company_id.value,
                role:'CLIENT'
            };
            let token = localStorage.getItem('token');
            if(this.state.formIsValid === true && this.state.saved === false){
                this.setState({saved:true});
                api.post({url: 'users', data: userData},token).then(
                    response => {
                        if(response.status === 201){
                            window.location.href = `/#/users`
                        }
                    }
                ).catch(
                    e => {
                        if(e.response.status == 409){
                            this.setEmailConflictError()
                        }
                        else{
                            console.log(e)
                        }
                    }
                )
            }
        }
    }

    render() {
        return (
            <div className="PageContent">
                <Title>Új felhasználó</Title>
                <Form submitHandler={this.onSubmitHandler} formElements={this.state.createForm} formIsValid={this.state.formIsValid} changeHandler={this.inputChangeHandler} />
            </div>
        );
    }
}

export default Create;