import React, { Component } from 'react';
import ViewComponent from '../../../components/UI/Views/Views';
import Title from '../../../components/UI/Title/Title';
import api from '../../../api/api';
import {NavLink} from 'react-router-dom'


class View extends Component {
    state = {
        viewElements: {
            user: {
                value: '',
                labelName: 'Felhasználó',
            },
            email: {
                value: '',
                labelName: 'E-mail',
            },
            company: {
                value: '',
                labelName: 'Foglalkoztató cég',
            },
            document:{
                value:'',
                labelName:'Felhasználó típusa'
            },  
            processing_started:{
                value:'',
                labelName:'Feldolgozás elkezdve'
            }, 
            expire_at:{
                value:'',
                labelName:'Lejárati idő',
            }    
        },
        status:{
            value:'',
        }
    }

    componentWillMount(){
      this.getDataFromServer();
    }

    getDataFromServer = () => {
        const {id: jobId} = this.props.match.params
        let token = localStorage.getItem('token')
        api.get({url: 'jobs/'+jobId},token).then(
            response => {
                const viewForm = {
                    ...this.state.viewElements
                };
                let status = response.data.status;
                viewForm.user.value = response.data.user;
                viewForm.company.value = response.data.company;
                viewForm.document.value = response.data.document;
                viewForm.email.value = response.data.email;
                viewForm.expire_at.value = response.data.expire_at;
                viewForm.processing_started.value = response.data.processing_started;
                const statusStatet = {
                    ...this.state.status
                };
                statusStatet.value = status;
                this.setState({ viewElements: viewForm});
                this.setState({ status: statusStatet});
            }
        ).catch(
            e => {
               console.log(e);
            }
        )
    }

    onChangeHandler = (event) => {
        let token = localStorage.getItem('token')

        const putData={
            status : event.target.value,
        }
        const {id: jobId} = this.props.match.params
        api.put({url: 'jobs/'+jobId, data: putData},token).then(
            response => {
                if(response.status === 200){
                    alert('Állapot módosítása sikeresen megtörtént!');
                }
            }
        ).catch(
            e => {
                console.log(e);
            }
        )
    }

    render() {
 
        return (
            <div className="PageContent">
                <Title>Munka</Title>
                <ViewComponent viewElements={this.state.viewElements} />
                <p className="bold-text">Állapot</p>
                <select value={this.state.status.value} className="form-control max200-px" onChange={(event) => this.onChangeHandler(event)}>
                    <option value="Preparatory">Preparatory</option>
                    <option value="InProgress">InProgress</option>
                    <option value="Waiting">Waiting</option>
                    <option value="Done">Done</option>
                </select> 
                <NavLink to='/jobs'  exact>
                    <span className="fa fa-arrow-left">Vissza</span>
                </NavLink>
            </div>
        );
    }
}

export default View;