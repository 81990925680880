import React, { Component } from 'react';
import Title from '../../../components/UI/Title/Title';
import Form from '../../../components/Forms/Forms';
import api from '../../../api/api';
import {isEmailValid, checkPasswordLength, isPhoneNumber,lowerThenMaxLength} from '../../../Utils';


let validationErrorFrom = '';
let token = localStorage.getItem('token')
let clickNumber = 0;


class Update extends Component {
    state = {
        updateForm: {
            firstname: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                validation: {
                    required: true,
                    maxLength:true,
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Vezetéknév',
                valid: true,
                touched: false
            },
            lastname: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                validation: {
                    required: true,
                    maxLength:true,
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Keresztnév',
                valid: true,
                touched: false
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'email',
                    placeholder: ''
                },
                value: '',
                validation: {
                    required: true,
                    isEmail:true
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'E-mail',
                valid: true,
                touched: false
            },
            password: {
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: ''
                },
                value: '',
                validation: {
                    required: false
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Password',
                valid: true,
                touched: false
            },
            country: {
                elementType: 'select',
                elementConfig: {
                    options:[
                        {value: '0', displayValue:'Loading...'},
                    ]
                },
                value: '',
                validation: {
                    required: false
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Ország',
                valid: true,
            },
            address: {
                elementType: 'textarea',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                validation: {
                    required: false,
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Lakcím',
                valid: true,
                touched: false
            },
            role : {
                elementType: 'select',
                elementConfig: {
                    options:[
                        {value: 'ADMIN', displayValue:'Admin'},
                        {value: 'OFFICE', displayValue:'Office'},
                    ]
                },
                value: '',
                validation: {
                    required: false
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Típus',
                valid: true,
            },
            status : {
                elementType: 'select',
                elementConfig: {
                    options:[
                        {value: 'active', displayValue:'Aktív'},
                        {value: 'inactive', displayValue:'inaktív'},
                    ]
                },
                value: '',
                validation: {
                    required: false
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Állapot',
                valid: true,
            },
        },
        formIsValid: true
    }

    componentWillMount(){
        this.getUserData();
        this.getCountries();
    }

    getUserData = () => {
        var url = window.location.pathname;
        const {id: userId} = this.props.match.params
        api.get({url: 'users/'+userId},token).then(
            response => {
                const updatedForm = {
                    ...this.state.updateForm
                };
                updatedForm.email.value = response.data.email;
                updatedForm.firstname.value = response.data.firstName;
                updatedForm.lastname.value = response.data.lastName;
                updatedForm.country.value = response.data.countryId;
                updatedForm.address.value = response.data.address;
                updatedForm.status.value = response.data.status === 'active'? 'active' : 'inactive';
                updatedForm.role.value = response.data.role;
                this.setState({ updateForm: updatedForm});
            }
        ).catch(
            e => {
               console.log(e);
            }
        )
    }

    getCountries = () => {
        api.get({url: 'countries'},token).then(
            response => {
                var selectData = [];
                var resp = response.data
                for (let identifier in resp) {
                    selectData.push({value: resp[identifier].id, displayValue:resp[identifier].name})
                }
                const updatedForm = {
                    ...this.state.updateForm
                };
                updatedForm.country.elementConfig.options = selectData;
                updatedForm.country.value = selectData[0].value;
                this.setState({ createForm: updatedForm});
                selectData = [];
            }
        ).catch(
            e => {
               console.log(e);
            }
        )
    }

    componentDidUpdate(){
        validationErrorFrom = '';
    }

    inputChangeHandler = (event, inputIdentifier) => {

        const updatedForm = {
            ...this.state.updateForm
        };
        const updatedFormElement = {
            ...updatedForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;

        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        if (!updatedFormElement.valid) {
            updatedFormElement.validationMessage = this.setMessage(updatedFormElement.validation, updatedFormElement.labelName);
        }
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;


        let formIsValid = true;
        for (let inputIdentifier in updatedForm) {
            formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({ updateForm: updatedForm, formIsValid: formIsValid });
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }
        if (rules.required && validationErrorFrom=='') {
            isValid = value.trim() !== '' && isValid;
            if(!isValid){
                validationErrorFrom = "required";
            }

        }
        if (rules.isEmail && validationErrorFrom==''){
            var localValidator = isEmailValid(value);
            isValid = localValidator && isValid;
            if(!isValid){
                validationErrorFrom = "isEmail";
            }
        }
        if(rules.minLength && validationErrorFrom == ''){
            var localValidator = checkPasswordLength(value,rules.minLength);
            isValid = localValidator && isValid;
            if(!isValid){
                validationErrorFrom = "minLength";
            }
        }
        return isValid;
    }

    setMessage(rules, label) {
        let message = '';
        if (!rules) {
            return '';
        }
        if (rules.required && validationErrorFrom == 'required') {
            message = [label, 'nem lehet üres!'].join(' ');
        }
        if (rules.isEmail && validationErrorFrom == 'isEmail') {
            message ='Helytelen e-mail cím!';
        }
        if (rules.minLength && validationErrorFrom == 'minLength') {
            message = ['A(z) ', label, ' legalább ', rules.minLength, ' karaktert kell tartalmazzon!'];
        }
        return message;
    }

    onSubmitHandler = (event) => {
        if(clickNumber == 0){
            clickNumber =1;
        event.preventDefault();
        let userData = { 
            email:this.state.updateForm.email.value, 
            firstName: this.state.updateForm.firstname.value,
            lastName: this.state.updateForm.lastname.value,
            countryId:parseInt(this.state.updateForm.country.value),
            address:this.state.updateForm.address.value,
            status:this.state.updateForm.status.value,
            role:this.state.updateForm.role.value,
            password:this.state.updateForm.password.value,
        };
        
        let token = localStorage.getItem('token');
        const {id: userId} = this.props.match.params
        if(this.state.formIsValid === true){
            api.put({url: 'users/'+userId, data: userData},token).then(
                response => {
                    if(response.status === 200){
                        window.location.href = `/#/admin`
                    }
                }
            ).catch(
                e => {
                        console.log(e)
                }
            )
        } }
    }
    render() {
        return (
            <div className="PageContent">
                <Title>Felhasználó módosítás</Title>
                <Form submitHandler={this.onSubmitHandler} formElements={this.state.updateForm} formIsValid={this.state.formIsValid} changeHandler={this.inputChangeHandler} />
            </div>
        );
    }
}

export default Update;