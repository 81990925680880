import React from 'react';


import NavigationItem from './NavigationItem/NavigationItem';

// const logouthandler = (props) => {
//     event.preventDefault();
//     localStorage.removeItem("token")
//     props.onSetLoggedOut();
// }

const loggedInUserType = localStorage.getItem('type');

const navigationItems = (props) => (
    <ul className="NavigationItems">
        {/* <NavigationItem link="/" exact>Hom</NavigationItem> */}
        <NavigationItem link="/companies" >Cégek</NavigationItem>
        <NavigationItem link="/monitoring" >Monitoring</NavigationItem>
        <NavigationItem link="/jobs" >Munkák</NavigationItem>
        <NavigationItem link="/documents" >Dokumentum tipusok</NavigationItem>
        <NavigationItem link="/users" >Ügyfelek</NavigationItem>
        {loggedInUserType && loggedInUserType === "ADMIN" && <NavigationItem link="/admin" >Admin</NavigationItem>}
        <NavigationItem link="/logs" >Logok</NavigationItem>
        <NavigationItem link="/login" clicked={props.logoutFunction}>Kijelentkezés</NavigationItem>
    </ul>
);



export default navigationItems;