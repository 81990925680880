import { TYPES } from './actions';
const initialStates = {
  testGroup: {
    exampleValue: 'test'
  },
  localeGroup: {
    locale: 'hu'
  },
  loginState: {
    isLoggedIn:false
  }
};



export const testGroup = (state = initialStates.testGroup, action) => {
  switch (action.type) {
    case TYPES.SET_EXAMPLE_VALUE:
      return Object.assign({}, state, { exampleValue: action.text });
    default:
      return state;
  }
};
export const localeGroup = (state = initialStates.localeGroup, action) => {
  switch (action.type) {
    case TYPES.SET_LOCALE: {
      return {
        ...state,
        locale: action.locale
      };
    }
    default:
      return state;
  }
};

export const userGroup = (state = initialStates.loginState,action) => {
  switch(action.type){
    case TYPES.SET_LOGGED_IN:
      return {
        ...state,
        isLoggedIn:true
      }
    case TYPES.SET_LOGGED_OUT:
        localStorage.removeItem('token')
      return {
        ...state,
        isLoggedIn:false
      }
    case TYPES.GET_LOGIN_STATUS:
      return {
        ...state,
      }
    default:
   
      return state;
      
  }
}
