import React from 'react'

// import burgerLogo from '../../assets/images/burger-logo.png';
import logoImg from '../../logo.png';



const logo = (props) => (
    <div className="Logo">
        <img src={logoImg} alt="myLogo" />
    </div>
);

export default logo;