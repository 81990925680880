export const firstname = "firstname";
export const lastName = "lastName";
export const usersPerPage = 30;
export const documentsPerPage = 30;
export const sender = 'sender';
export const deleted = 'deleted';
export const subject = 'subject';
export const createdAt = 'createdAt';
export const text = 'text';
export const type = 'type';
export const reporter = 'reporter';
export const challenge = 'challenge';
export const video = 'video';
export const owner = 'owner';
export const name = 'name';
export const description = 'description';
export const userId = 'userId';
export const user = 'user';
export const goalType = 'goalType';
export const category = 'category';
export const status = 'status';
export const document = 'document';
export const expire_at = 'expire_at';
export const operation = 'operation';
