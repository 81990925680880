import React, { Component } from 'react';
import Title from '../../../components/UI/Title/Title';
import Form from '../../../components/Forms/Forms';
import api from '../../../api/api';
import {isNumber} from '../../../Utils';


let validationErrorFrom = '';
let clickNumber = 0;

class Create extends Component {
    state = {
        createForm: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                validation: {
                    required: true,
                    maxLength:true,
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Megnevezés',
                valid: false,
                touched: false
            },
            description: {
                elementType: 'textarea',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                validation: {
                    required: true,
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Leírás',
                valid: false,
                touched: false
            },
            required_administration_time: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                validation: {
                    required: true,
                    isNumber:true,
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Átfutási idő (nap)',
                valid: false,
                touched: false
            },
            status: {
                elementType: 'input',
                elementConfig: {
                    type: 'checkbox',
                    placeholder: ''
                },
                value:false,
                hasLabel: true,
                labelName: 'Engedélyezett',
            }
        },
        formIsValid: false,
        saved:false,
    }


    componentDidUpdate(){
        validationErrorFrom = '';
    }

    inputChangeHandler = (event, inputIdentifier) => {

        const updatedForm = {
            ...this.state.createForm
        };
        const updatedFormElement = {
            ...updatedForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;

        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        if (!updatedFormElement.valid) {
            updatedFormElement.validationMessage = this.setMessage(updatedFormElement.validation, updatedFormElement.labelName);
        }
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;


        let formIsValid = true;
        for (let inputIdentifier in updatedForm) {
            formIsValid = updatedForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({ createForm: updatedForm, formIsValid: formIsValid });
    }


    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }
        if (rules.required && validationErrorFrom=='') {
            isValid = value.trim() !== '' && isValid;
            if(!isValid){
                validationErrorFrom = "required";
            }

        }
        if (rules.isNumber && validationErrorFrom==''){
            var localValidator = isNumber(value);
            isValid = localValidator && isValid;
            if(!isValid){
                validationErrorFrom = "isNumber";
            }
        }
        
        return isValid;
    }

    setMessage(rules, label) {
        let message = '';
        if (!rules) {
            return '';
        }
        if (rules.required && validationErrorFrom == 'required') {
            message = [label, 'nem lehet üres!'].join(' ');
        }
        if (rules.isNumber && validationErrorFrom == 'isNumber') {
            message =['A(z) ', label, ' csak számokat tartalmazhat!'];
        }
        if (rules.minLength && validationErrorFrom == 'minLength') {
            message = ['A(z) ', label, ' legalább ', rules.minLength, ' karaktert kell tartalmazzon!'];
        }
        return message;
    }

    onSubmitHandler = (event) => {
        if(clickNumber == 0){
            clickNumber =1;
            event.preventDefault();
            let documentTypeData = { 
                name:this.state.createForm.name.value, 
                description: this.state.createForm.description.value,
                required_administration_time:this.state.createForm.required_administration_time.value,
                enabled:this.state.createForm.status.value ? 1 :0,
            };
            let token = localStorage.getItem('token');
            if(this.state.formIsValid === true && this.state.saved === false){
                this.setState({saved:true});
                api.post({url: 'document-types', data: documentTypeData},token).then(
                    response => {
                        if(response.status === 201){
                            window.location.href = `/#/documents`
                        }
                    }
                ).catch(
                    e => {
                        console.log(e)
                        
                    }
                )
            }
        }
    }

    render() {
        return (
            <div className="PageContent">
                <Title>Új dokumentum típus</Title>
                <Form submitHandler={this.onSubmitHandler} formElements={this.state.createForm} formIsValid={this.state.formIsValid} changeHandler={this.inputChangeHandler} />
            </div>
        );
    }
}

export default Create;