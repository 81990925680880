import React, { Component } from 'react';
import Title from '../../../components/UI/Title/Title';
import Form from '../../../components/Forms/Forms';
import api from '../../../api/api';
import {isNumber} from '../../../Utils';



let validationErrorFrom = '';
let token = localStorage.getItem('token')
let clickNumber = 0;


class Update extends Component {
    state = {
        updateForm: {
            name: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                validation: {
                    required: true,
                    maxLength:true,
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Megnevezés',
                valid: true,
                touched: false
            },
            description: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                validation: {
                    required: true,
                    maxLength:true,
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Leírás',
                valid: true,
                touched: false
            },
            required_administration_time: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                validation: {
                    required: true,
                    isNumber:true,
                },
                validationMessage: '',
                hasLabel: true,
                labelName: 'Átfutási idő (nap)',
                valid: true,
                touched: false
            },
            status: {
                elementType: 'input',
                elementConfig: {
                    type: 'checkbox',
                    placeholder: ''
                },
                value:false,
                hasLabel: true,
                labelName: 'Engedélyezett',
                checked: ''
            },
        },
        formIsValid: false
    }

    componentWillMount(){
        this.getDocumentData();
    }

    getDocumentData = () => {        
        const {id: documentId} = this.props.match.params
        api.get({url: 'document-types/'+documentId},token).then(
            response => {
                const updatedForm = {
                    ...this.state.updateForm
                };
                updatedForm.name.value = response.data.name;
                updatedForm.description.value = response.data.description;
                updatedForm.required_administration_time.value = response.data.required_administration_time;
                updatedForm.status.checked = response.data.enabled == 1 ? 'checked' : '';
                updatedForm.status.value = response.data.enabled;
                this.setState({ updateForm: updatedForm});
            }
        ).catch(
            e => {
               console.log(e);
            }
        )
    }

    componentDidUpdate(){
        validationErrorFrom = '';
    }

    inputChangeHandler = (event, inputIdentifier) => {

        const updatedForm = {
            ...this.state.updateForm
        };
        const updatedFormElement = {
            ...updatedForm[inputIdentifier]
        };
        
        if(updatedFormElement.elementConfig.type === 'checkbox'){
            updatedFormElement.value = !updatedFormElement.value
            if(updatedFormElement.value == true){
                updatedFormElement.checked = 'checked';
            }
            else{
                updatedFormElement.checked = ''
            }
        }
        else{
            updatedFormElement.value = event.target.value;
        }
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        if (!updatedFormElement.valid) {
            updatedFormElement.validationMessage = this.setMessage(updatedFormElement.validation, updatedFormElement.labelName);
        }
        updatedFormElement.touched = true;
        updatedForm[inputIdentifier] = updatedFormElement;


        let formIsValid = true;
        for (let inputIdentifier in updatedForm) {
            if(updatedForm[inputIdentifier].elementConfig.type != 'checkbox')
            formIsValid = updatedForm[inputIdentifier].valid && formIsValid ;
        }
        this.setState({ updateForm: updatedForm, formIsValid: formIsValid });
    }

    checkValidity(value, rules) {
        let isValid = true;
        if (!rules) {
            return true;
        }
        if (rules.required && validationErrorFrom=='') {
            isValid = value.trim() !== '' && isValid;
            if(!isValid){
                validationErrorFrom = "required";
            }

        }
        if(rules.isNumber && validationErrorFrom == ''){
            var localValidator = isNumber(value);
            isValid = localValidator && isValid;
            if(!isValid){
                validationErrorFrom = "isNumber";
            }
        }
        return isValid;
    }

    setMessage(rules, label) {
        let message = '';
        if (!rules) {
            return '';
        }
        if (rules.required && validationErrorFrom == 'required') {
            message = [label, 'nem lehet üres!'].join(' ');
        }
        if (rules.isNumber && validationErrorFrom == 'isNumber') {
            message = ['A(z) ', label, ' csak számokat tartalmazhat!'];
        }
        return message;
    }

    onSubmitHandler = (event) => {
        if(clickNumber == 0){
            clickNumber =1;
        event.preventDefault();
        let userData = { 
            name:this.state.updateForm.name.value, 
            description: this.state.updateForm.description.value,
            required_administration_time: this.state.updateForm.required_administration_time.value,
            enabled: this.state.updateForm.status.value ? 1 : 0,
        };
        
        let token = localStorage.getItem('token');    
        const {id: documentId} = this.props.match.params
        if(this.state.formIsValid === true){
            api.put({url: 'document-types/'+documentId, data: userData},token).then(
                response => {
                    if(response.status === 200){
                        window.location.href = `/#/documents`
                    }
                }
            );
        } }
    }
    render() {
        return (
            <div className="PageContent">
                <Title>Documentum típus módosítás</Title>
                <Form submitHandler={this.onSubmitHandler} formElements={this.state.updateForm} formIsValid={this.state.formIsValid} changeHandler={this.inputChangeHandler} />
            </div>
        );
    }
}

export default Update;