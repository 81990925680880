import React, { Component } from 'react';
import Title from '../../../components/UI/Title/Title';
import CreateBtn from '../../../components/Navigation/PageNavigationItems/CreateNavigationItem';
import Filter from '../../../components/UI/Filter/Filter';
import api from '../../../api/api';
import {UpdatePencil, DocumentRender, DeleteTrash} from '../../../components/UI/ActionItems/ActionItems';
import * as Constants from '../../../constant';
import {slugify} from '../../../Utils';
import { Link } from 'react-router-dom'
//let monitoringArray= [];
let monitoringList = null;
let token = localStorage.getItem('token')

class Monitoring extends Component {
    state = {
        monitoringArray:[],
        totalMonitoring:0,
        currentPage: 1,
        deleted:false,
        minPageLimit:1,
        maxPageLimit:3,
        difference:2,
        monitoringPerPage: Constants.usersPerPage,
        term:'',
        activeInput: '',
        sort: {
            column: null,
            direction: 'desc',
          },
        filterForm: {
            user: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                hasLabel: false,
                touched: false
            },
            document: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                hasLabel: false,
                touched: false
            },
            job_id: {
              elementType: 'input',
              elementConfig: {
                  type: 'text',
                  placeholder: ''
              },
              value: '',
              hasLabel: false,
              touched: false
          },
            expire_at: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                hasLabel: false,
                touched: false,
            },
            enabled: {
              elementType: 'select',
              elementConfig: {
                options:[
                  {value: '', displayValue:'Összes'},
                  {value: 1, displayValue:'Aktív'},
                  {value: 0, displayValue:'Inaktív'},
                  
              ]
              },
              value: '',
              hasLabel: false,
              touched: false,
          },
        },
    }

    componentDidMount(){
      this.getDataFromServer();
    }

    getDataFromServer = () => {
      api.get({url: 'monitoring'},token).then(
        response => {
            this.setState({monitoringArray:response.data})
            this.setState({totalMonitoring:response.data.length})

        }
    ).catch(
        e => {
           console.log(e);
        }
    )
    }

    inputChangeHandler = (event, inputIdentifier) => {
        
      const updatedFilter = {
          ...this.state.filterForm
      };
      const updatedFilterElement = {
          ...updatedFilter[inputIdentifier]
      };
      updatedFilterElement.value = event.target.value;
      updatedFilterElement.touched = true;
      updatedFilter[inputIdentifier] = updatedFilterElement;


      this.setState({ filterForm: updatedFilter, term: event.target.value, activeInput:inputIdentifier});
  }

    onSort = (column) => (e) => {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const sortedData = this.state.monitoringArray.sort((a, b) => {
          if (column === Constants.user) {
            const nameA = slugify(a.user.trim().toUpperCase()); // ignore upper and lowercase
            const nameB = slugify(b.user.trim().toUpperCase()); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          } else if (column === Constants.document) {
            let nameA = slugify(a.document.trim().toLowerCase()); // ignore upper and lowercase
            let nameB = slugify(b.document.trim().toLowerCase());
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
    
          } else {
            const nameA = slugify(a.expire_at.trim().toUpperCase()); // ignore upper and lowercase
            const nameB = slugify(b.expire_at.trim().toUpperCase()); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          }
        });
          
        if (direction === 'desc') {
          sortedData.reverse();
        }
        
        this.setState({
          data: sortedData,
          sort: {
            column,
            direction,
          }
        });
      };

    setArrow = (column) => {
    let className = 'sort-direction';
    
    if (this.state.sort.column === column) {
        className += this.state.sort.direction === 'asc' ? ' fa fa-arrow-up' : ' fa fa-arrow-down';
    }
    return className;
    };

    currentPageHandler = (e) => {
        this.setState({
          currentPage: Number(e.target.id)
        });
      }

    searchingFor = (term,identifier) => {
    return function(data) {
      if(identifier === 'enabled'){
        return (identifier!== '' && String(data.enabled)=== term) || !term;
      }
      else if(identifier == 'role'){
        return (identifier!= '' && String(data.role)== term) || !term;
      }
      else{
          return (identifier!= '' && data[identifier].toLowerCase().includes(term.toLowerCase())) || !term;
      }
    }
    }

    componentDidUpdate(){
      if(this.state.deleted == true){
        // this.setState({monitoringArray:null})
        setTimeout(() => {
          this.getDataFromServer();
          this.setState({deleted:false})
        }, 150);
        
        
      }
    }

    changeData = () => {
      this.setState({deleted:true})
    }

    increaseing = () => {
      if(this.state.maxPageLimit < Math.ceil(this.state.monitoringArray.length / this.state.monitoringPerPage)){
          this.setState({ maxPageLimit: this.state.maxPageLimit+1, minPageLimit:this.state.minPageLimit+1});
      }
    }

    decreaseing = () => {
      if(this.state.minPageLimit > 1){
          this.setState({ maxPageLimit: this.state.maxPageLimit-1, minPageLimit:this.state.minPageLimit-1});
      }
    }
    decreaseingToMinim = () => {
      this.setState({ maxPageLimit: this.state.difference+1, minPageLimit:1});
    }

    increaseingToMaxim = () => {
      this.setState({ maxPageLimit: Math.ceil(this.state.monitoringArray.length / this.state.monitoringPerPage), minPageLimit:Math.ceil(this.state.monitoringArray.length / this.state.monitoringPerPage)-this.state.difference});
    }


    render() {
        const { monitoringArray, term, currentPage, monitoringPerPage } = this.state;
        const indexOfLastMonitoring = currentPage * monitoringPerPage;
        const indexOfFirstMonitoring = indexOfLastMonitoring - monitoringPerPage;
        const currentMonitoring = this.state.monitoringArray.length ? this.state.monitoringArray.slice(indexOfFirstMonitoring, indexOfLastMonitoring) : null;
        const monitoringListWithTerm = this.state.monitoringArray.length ? (
            <tbody>
                {
                monitoringArray.filter(this.searchingFor(term,this.state.activeInput)).map(data => {
                return (
                  <tr key={data.id + Math.random()} className={data.color} data-key={data.id}>
                    <td>{data.id}</td>
                    <td >
                      <Link style={{color: '#343a40'}} to={`/users/view/${data.user_id}`}>{data.firstName} {data.lastName}</Link>
                    </td>
                    <td >
                      <Link style={{color: '#343a40'}} to={`/company/view/${data.company_id}`}>{data.company}</Link>
                    </td>
                    <td>{data.document}</td>
                    <td >
                      {data.job_id ? <Link style={{color: '#343a40'}} to={`/jobs/update/${data.job_id}`}>Munka részletek</Link> : 'Nincs munka hozzárendelve'}
                    </td>
                    <td>{data.expire_at}</td>
                    <td>{data.enabled ==  1? 'Aktív' : "Inaktív"}</td>                 
                  </tr>
                ) 
                })
                }
            </tbody>
            
          ) : (
            <tbody>
                <tr><th>No data found...</th></tr>
            </tbody>
          )
        monitoringList = currentMonitoring ? (
            <tbody>
                {
                    currentMonitoring.map(data => (
                        <tr key={data.id + Math.random()} className={data.color} data-key={data.id}>
                            <td>{data.id}</td>
                            <td >
                              <Link style={{color: '#343a40'}} to={`/users/view/${data.user_id}`}>{data.firstName} {data.lastName}</Link>
                            </td>
                            <td >
                              <Link style={{color: '#343a40'}} to={`/company/view/${data.company_id}`}>{data.company}</Link>
                            </td>
                            <td>{data.document}</td>
                            <td >
                            {data.job_id ? <Link style={{color: '#343a40'}} to={`/jobs/update/${data.job_id}`}>Munka részletek</Link> : 'Nincs munka hozzárendelve'}
                            </td>
                            <td>{data.expire_at}</td>
                            <td>{data.enabled == 1 ? 'Aktív' : "Inaktív"}</td>                         
                        </tr>
                    ))
            
                }
            </tbody>
        ) : (
            <tbody>
                <tr><th>No data found...</th></tr>
            </tbody>
          );

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(monitoringArray.length / monitoringPerPage); i++) {
          if(i >= this.state.minPageLimit && i <= this.state.maxPageLimit)
            pageNumbers.push(i);
        }
        
        const renderPageNumbers = pageNumbers.map(number => {
            return (
              <li
                key={number}
                id={number}
                onClick={this.currentPageHandler}
              >
                {number}
              </li>
            );
          });
        
        return (
            <div className="PageContent">
                <Title >Monitoring</Title>
                <div className="summary">Megjelenik {this.state.totalMonitoring <monitoringPerPage ? this.state.totalMonitoring : monitoringPerPage} a(z) {this.state.totalMonitoring} -ből.</div>
                <table className="table table-striped table-bordered user-table">
                {monitoringArray.length && <thead>
                        <tr>
                            <th>#</th>
                            <th onClick={this.onSort('firstName')}>Felhasználó<span className={this.setArrow('firstName')}></span></th>
                            <th onClick={this.onSort('company')}>Foglalkoztató cég<span className={this.setArrow('company')}></span></th>
                            <th onClick={this.onSort('document')}>Documentum<span className={this.setArrow('document')}></span></th>
                            <th onClick={this.onSort('job_id')}>Munka<span className={this.setArrow('job_id')}></span></th>
                            <th onClick={this.onSort('expire_at')}>Lejárati idő<span className={this.setArrow('expire_at')}></span></th>
                            <th onClick={this.onSort('enabled')}>Aktív<span className={this.setArrow('enabled')}></span></th>
                        </tr>
                        <Filter filterElements={this.state.filterForm} change={this.inputChangeHandler} />
                    </thead>}
                    {term.length ? monitoringListWithTerm : monitoringList}                    
                </table>
                {term.length || !monitoringArray.length ? null : 
                <div id="page-numbers">
                  <li ><i onClick={this.decreaseingToMinim} className="fa fa-angle-double-left"></i></li>
                    <li ><i onClick={this.decreaseing} style={{fontSize: '20', paddingTop: 3}} className="fa fa-angle-left"></i></li> 
                    {renderPageNumbers} 
                    <li><i onClick={this.increaseing} style={{fontSize: '20', paddingTop: 3}} className="fa fa-angle-right"></i></li>
                    <li ><i onClick={this.increaseingToMaxim} className="fa fa-angle-double-right"></i></li>
                </div>
                }
            </div>

        );
    }
}

export default Monitoring;