import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import DatePicker from 'react-date-picker';
import CKEditor from "react-ckeditor-component";


let inputClasses = [];


const input = (props) => {
    let inputElement = null;
    
    if(props.labelName == 'Cél'){
        if(props.showProperty == false)
        {
            inputClasses = [];
            inputClasses.push("hidden");
        }
    
    }
    if (props.invalid && props.shouldValidate && props.touched)
    { 
        inputClasses = [];
        inputClasses.push("invalid");
    }
    
    switch (props.elementType) {
        case ('input'):
            if(props.elementConfig.type === 'checkbox'){
                inputElement = <input checked={props.checkboxProperty} className={[props.className, inputClasses].join(' ')} value={props.value} {...props.elementConfig} onChange={props.changed} />
            }
            else{
                inputElement = <input accept={props.acceptedFileFormat} id={props.acceptedFileFormat ? 'videoFile' : ''} className={[props.className, inputClasses].join(' ')} value={props.value} {...props.elementConfig} onChange={props.changed} />
            }
            break;
        case ('select'):
            inputElement = (
                <select
                className={[props.className, inputClasses].join(' ')}
                value={props.value}
                onChange={props.changed}>
                    {props.elementConfig.options.map(option => (
                        <option key={option.value} 
                        value={option.value}>
                            {option.displayValue}
                        </option>
                    ))}
                </select>
            );
            
            break;
        case('dateTime'):
            inputElement = (
                <div>
                <DateTimePicker
                    onChange={props.dateChanged}
                    locale='hu-HU'
                    value={props.value}
                    calendarClassName={[props.className, inputClasses].join(' ')}
                />      
                </div>
               
            );
            break;
        case('date'):
            inputElement = (
                <div>
                <DatePicker
                    onChange={props.dateChanged}
                    value={props.value}
                    calendarClassName={[props.className, inputClasses].join(' ')}
                />
                </div>
               
            );
            break;
        case('textarea'):
        inputElement = (
            <textarea className={[props.className, inputClasses].join(' ')} value={props.value}  onChange={props.changed}  rows="4" cols="50"></textarea> 
        )
            break;
        case('editor'):
        inputElement = (
            <CKEditor 
            activeClass={[props.className, inputClasses].join(' ')}
            content={props.value} 
            events={{
                "change": props.changed
              }}
           />
        )
        break; 
       default:
            inputElement = <input
                {...props.elementConfig}
                value={props.value}
                onChange={props.change}
            />
    }

    if (props.hasLabel) {
        if (props.elementConfig.type === 'checkbox') {
            return (<div className="form-group">
                <label className="inputLabel">{inputElement} {props.labelName}</label>

            </div>)

        }
        else {
            return (
                <div className="form-group">
                    <label className={['inputLabel',inputClasses].join(' ')}>{props.shouldValidate.required == true ? <span className="redStar">*</span> : <span></span>} {props.labelName}</label>
                    {inputElement}
                    {props.invalid && props.shouldValidate && props.touched ? <p className="errorMessage" >{props.message}</p> : null}
                    {inputClasses=[]}

                </div>
            )
        }

    }
    else {
        return (<div className="form-group">{inputElement}</div>)

    }
}

export default input;