import React, { Component } from 'react';
import Title from '../../../components/UI/Title/Title';
import CreateBtn from '../../../components/Navigation/PageNavigationItems/CreateNavigationItem';
import Filter from '../../../components/UI/Filter/Filter';
import api from '../../../api/api';
import {UpdatePencil, DocumentRender, DeleteTrash, ViewEye} from '../../../components/UI/ActionItems/ActionItems';
import * as Constants from '../../../constant';
import {slugify} from '../../../Utils';

//let userArray= [];
let userList = null;
let token = localStorage.getItem('token');
let roles = localStorage.getItem('roles');

class Users extends Component {
    state = {
        userArray:[],
        totalUsers:0,
        currentPage: 1,
        deleted:false,
        minPageLimit:1,
        maxPageLimit:3,
        difference:2,
        usersPerPage: Constants.usersPerPage,
        term:'',
        activeInput: '',
        sort: {
            column: null,
            direction: 'desc',
          },
        filterForm: {
            firstName: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                hasLabel: false,
                touched: false
            },
            lastName: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                hasLabel: false,
                touched: false
            },
            email: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                hasLabel: false,
                touched: false,
            },
            company: {
                elementType: 'input',
                elementConfig: {
                    type: 'text',
                    placeholder: ''
                },
                value: '',
                hasLabel: false,
                touched: false,
            },
            status: {
              elementType: 'select',
              elementConfig: {
                options:[
                  {value: '', displayValue:'Összes'},
                  {value: 'active', displayValue:'Aktív'},
                  {value: 'inactive', displayValue:'Inaktív'},
                  
              ]
              },
              value: '',
              hasLabel: false,
              touched: false,
          },
        },
    }

    componentDidMount(){
      this.getDataFromServer();
    }

    getDataFromServer = () => {
      api.get({url: 'users'},token).then(
        response => {
            this.setState({userArray:response.data})
            this.setState({totalUsers:response.data.length})

        }
    ).catch(
        e => {
           console.log(e);
        }
    )
    }

    inputChangeHandler = (event, inputIdentifier) => {
        
      const updatedFilter = {
          ...this.state.filterForm
      };
      const updatedFilterElement = {
          ...updatedFilter[inputIdentifier]
      };
      updatedFilterElement.value = event.target.value;
      updatedFilterElement.touched = true;
      updatedFilter[inputIdentifier] = updatedFilterElement;


      this.setState({ filterForm: updatedFilter, term: event.target.value, activeInput:inputIdentifier});
  }

    onSort = (column) => (e) => {
        const direction = this.state.sort.column ? (this.state.sort.direction === 'asc' ? 'desc' : 'asc') : 'desc';
        const sortedData = this.state.userArray.sort((a, b) => {
          if (column === Constants.firstname) {
            const nameA = slugify(a.firstName.trim().toUpperCase()); // ignore upper and lowercase
            const nameB = slugify(b.firstName.trim().toUpperCase()); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          } else if (column === Constants.lastName) {
            let nameA = slugify(a.lastName.trim().toLowerCase()); // ignore upper and lowercase
            let nameB = slugify(b.lastName.trim().toLowerCase());
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
    
          } else {
            const nameA = slugify(a.email.trim().toUpperCase()); // ignore upper and lowercase
            const nameB = slugify(b.email.trim().toUpperCase()); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          }
        });
          
        if (direction === 'desc') {
          sortedData.reverse();
        }
        
        this.setState({
          data: sortedData,
          sort: {
            column,
            direction,
          }
        });
      };

    setArrow = (column) => {
    let className = 'sort-direction';
    
    if (this.state.sort.column === column) {
        className += this.state.sort.direction === 'asc' ? ' fa fa-arrow-up' : ' fa fa-arrow-down';
    }
    return className;
    };

    currentPageHandler = (e) => {
        this.setState({
          currentPage: Number(e.target.id)
        });
      }

    searchingFor = (term,identifier) => {
    return function(user) {
      if(identifier === 'status'){
        return (identifier!== '' && String(user.status)=== term) || !term;
      }
      else if(identifier == 'role'){
        console.log('term',term)
        console.log('role',user.role)
        return (identifier!= '' && String(user.role)== term) || !term;
      }
      else{
          return (identifier!= '' && user[identifier].toLowerCase().includes(term.toLowerCase())) || !term;
      }
    }
    }

    componentDidUpdate(){
      if(this.state.deleted == true){
        // this.setState({userArray:null})
        setTimeout(() => {
          this.getDataFromServer();
          this.setState({deleted:false})
        }, 150);
        
        
      }
    }

    changeData = () => {
      this.setState({deleted:true})
    }

    increaseing = () => {
      if(this.state.maxPageLimit < Math.ceil(this.state.userArray.length / this.state.usersPerPage)){
          this.setState({ maxPageLimit: this.state.maxPageLimit+1, minPageLimit:this.state.minPageLimit+1});
      }
    }

    decreaseing = () => {
      if(this.state.minPageLimit > 1){
          this.setState({ maxPageLimit: this.state.maxPageLimit-1, minPageLimit:this.state.minPageLimit-1});
      }
    }
    decreaseingToMinim = () => {
      this.setState({ maxPageLimit: this.state.difference+1, minPageLimit:1});
    }

    increaseingToMaxim = () => {
      this.setState({ maxPageLimit: Math.ceil(this.state.userArray.length / this.state.usersPerPage), minPageLimit:Math.ceil(this.state.userArray.length / this.state.usersPerPage)-this.state.difference});
    }


    render() {
        const { userArray, term, currentPage, usersPerPage } = this.state;
        const indexOfLastUser = currentPage * usersPerPage;
        const indexOfFirstUser = indexOfLastUser - usersPerPage;
        const currentUsers = this.state.userArray.length ? this.state.userArray.slice(indexOfFirstUser, indexOfLastUser) : null;
        const userListWithTerm = this.state.userArray.length ? (
            <tbody>
                {
                userArray.filter(this.searchingFor(term,this.state.activeInput)).map(user => {
                return (
                  <tr key={user.id + Math.random()} data-key={user.id}>
                    <td>{user.id}</td>
                    <td >{user.firstName}</td>
                    <td>{user.lastName}</td>
                    <td>{user.email}</td>
                    <td>{user.company}</td>
                    <td>{user.status === "active" ? 'Aktív' : "Inaktív"}</td>
                    <td onClick={this.changeData} id={user.id} >
                        {roles && roles.search('UPDATE') >-1 && <UpdatePencil link={"/users/update/"+user.id}/>}
                        {roles && roles.search('UPDATE') >-1 && <DocumentRender link={"/users/documents/"+user.id}/>}
                        {/* {roles && roles.search('DELETE') >-1 && <DeleteTrash link={"users/"+user.id}/>} */}
                        <ViewEye exact={true} link={`users/view/${user.id}`}/>
                    </td>
                  </tr>
                ) 
                })
                }
            </tbody>
            
          ) : (
            <tbody>
                <tr><th>No data found...</th></tr>
            </tbody>
          )
        userList = currentUsers ? (
            <tbody>
                {
                    currentUsers.map(user => (
                        <tr key={user.id + Math.random()} data-key={user.id}>
                            <td>{user.id}</td>
                            <td >{user.firstName}</td>
                            <td>{user.lastName}</td>
                            <td>{user.email}</td>
                            <td>{user.company}</td>
                            <td>{user.status === "active" ? 'Aktív' : "Inaktív"}</td>
                            <td onClick={this.changeData} id={user.id} >
                                {roles && roles.search('UPDATE') >-1 && <UpdatePencil link={"/users/update/"+user.id}/>}
                                {roles && roles.search('UPDATE') >-1 && <DocumentRender link={"/users/documents/"+user.id}/>}
                                {/* {roles && roles.search('DELETE') >-1 && <DeleteTrash link={"users/"+user.id}/>} */}
                                <ViewEye exact={true} link={`users/view/${user.id}`}/>
                            </td>
                        </tr>
                    ))
            
                }
            </tbody>
        ) : (
            <tbody>
                <tr><th>No data found...</th></tr>
            </tbody>
          );

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(userArray.length / usersPerPage); i++) {
          if(i >= this.state.minPageLimit && i <= this.state.maxPageLimit)
            pageNumbers.push(i);
        }
        
        const renderPageNumbers = pageNumbers.map(number => {
            return (
              <li
                key={number}
                id={number}
                onClick={this.currentPageHandler}
              >
                {number}
              </li>
            );
          });
        
        return (
            <div className="PageContent">
                <Title >Felhasználók</Title>
                {roles && roles.search('CREATE') >-1 && <CreateBtn btnStyle="btn" link="users/create">+ Új Felhasználó </CreateBtn>}
                <div className="summary">Megjelenik {this.state.totalUsers <usersPerPage ? this.state.totalUsers : usersPerPage} a(z) {this.state.totalUsers} -ből.</div>
                <table className="table table-striped table-bordered user-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th onClick={this.onSort('firstname')}>Vezetéknév<span className={this.setArrow('firstname')}></span></th>
                            <th onClick={this.onSort('lastName')}>Keresztnév<span className={this.setArrow('lastName')}></span></th>
                            <th onClick={this.onSort('email')}>E-mail<span className={this.setArrow('email')}></span></th>
                            <th onClick={this.onSort('company')}>Foglalkoztató cég<span className={this.setArrow('company')}></span></th>
                            <th onClick={this.onSort('status')}>Aktív<span className={this.setArrow('status')}></span></th>
                            <th className="action-column"></th>
                        </tr>
                        <Filter filterElements={this.state.filterForm} change={this.inputChangeHandler} />
                    </thead>
                    {term.length ? userListWithTerm : userList}
                    {}
                </table>
                {term.length || !userArray ? null : 
                <div id="page-numbers">
                  <li ><i onClick={this.decreaseingToMinim} className="fa fa-angle-double-left"></i></li>
                    <li ><i onClick={this.decreaseing} style={{fontSize: '20', paddingTop: 3}} className="fa fa-angle-left"></i></li> 
                    {renderPageNumbers} 
                    <li><i onClick={this.increaseing} style={{fontSize: '20', paddingTop: 3}} className="fa fa-angle-right"></i></li>
                    <li ><i onClick={this.increaseingToMaxim} className="fa fa-angle-double-right"></i></li>
                </div>
                }
            </div>

        );
    }
}

export default Users;