const hostname = window && window.location && window.location.hostname;

// let ENDPOINT_BASE_URL = 'http://api-documentrecorder.nagyervin.ro/api/v1';
let ENDPOINT_BASE_URL = 'http://localhost:8001/api/v1';
// let ENDPOINT_BASE_URL = 'http://api.expatservices.hu/api/v1';
let BASE_URL = 'http://localhost:3000/';

if (hostname !== 'localhost') {
  ENDPOINT_BASE_URL = 'https://api.expatservices.hu/api/v1';
  BASE_URL = '';
}
const config = {
  ENDPOINT_BASE_URL: ENDPOINT_BASE_URL,
  BASE_URL: BASE_URL
};

export default config;
