import React, { Component } from 'react';
import ViewComponent from '../../../components/UI/Views/Views';
import Title from '../../../components/UI/Title/Title';
import api from '../../../api/api';
import {NavLink} from 'react-router-dom'


class View extends Component {
    state = {
        viewElements: {
            user: {
                value: '',
                labelName: 'Felhasználó',
            },
            operation: {
                value: '',
                labelName: 'Művelet',
            },
            newState: {
                value: '',
                labelName: 'Aktuális állapot',
            },
            prevState:{
                value:'',
                labelName:'Előző állapot'
            }, 
        },
    }

    componentWillMount(){
      this.getDataFromServer();
    }

    getDataFromServer = () => {
        const {id: logId} = this.props.match.params
        let token = localStorage.getItem('token')
        api.get({url: 'log/'+logId},token).then(
            response => {
                const viewForm = {
                    ...this.state.viewElements
                };
                viewForm.user.value = response.data.user;
                viewForm.operation.value = response.data.operation;
                viewForm.newState.value = response.data.newState;
                viewForm.prevState.value = response.data.prevState;
               
                this.setState({ viewElements: viewForm});
            }
        ).catch(
            e => {
               console.log(e);
            }
        )
    }

    render() {
        return (
            <div className="PageContent">
                <Title>Log</Title>
                <ViewComponent viewElements={this.state.viewElements} />
                <NavLink to='/logs'  exact>
                    <span className="fa fa-arrow-left">Vissza</span>
                </NavLink>
            </div>
        );
    }
}

export default View;